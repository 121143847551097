import styles from "./ErrorHandler.module.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router-dom";
const { Link } = require("react-router-dom");

export default function ErrorHandler({ title, desc }) {
	const navigate = useNavigate();

	function errorHandler() {
		navigate("/");
	}

	return (
		<div className={styles["error_container"]}>
			<div className={styles["text_container"]}>
				<WarningAmberIcon sx={{ fontSize: 90, color: "#ff0f0f" }} />
				<h1 className={styles["title_style"]}>Oops! {title}</h1>
				<div className={styles["text_style"]}>
					<div>{desc}</div>
				</div>
				<button className={`btn btn-primary` + " " + styles["error_button"]} onClick={() => errorHandler()}>
					Go to homepage
				</button>
			</div>
		</div>
	);
}
