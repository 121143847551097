import DOMPurify from "dompurify";
import styles from "./marketing.module.css";
import productStyles from "../productDetails.module.css";
import { useProductDetails } from "../productContext/ProductProvider";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function Marketing() {
	const { t } = useTranslation();
	const { productDetails, lng } = useProductDetails();

	const { marketingContent } = useMemo(() => getMarketingData(productDetails, lng), [productDetails, lng]);

	const { headlineCopy, marketingCopy, marketingCopy25, marketingCopy50, longDescription } = marketingContent;

	return (
		<section className={`${productStyles.section} ${styles.marketing}`}>
			<div className={`${productStyles.title} `}>{t("Ad Copy")}</div>
			<div className={productStyles.sub_section}>
				{headlineCopy && (
					<>
						<div className={productStyles.sub_section__title}>{t("Headlines")}</div>
						<p
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(headlineCopy),
							}}></p>
					</>
				)}
			</div>
			{(marketingCopy || marketingCopy25 || marketingCopy50) && (
				<div className={`${productStyles.sub_section} ${productStyles.col_sub_section}`}>
					<div className={productStyles.sub_section__title}>{t("Ad Copy")}</div>
					<div className={productStyles.sub_section__content}>
						{marketingCopy && (
							<div className={styles.marketing_stub}>
								<div className={styles.marketing_stub__title}>10 Words</div>
								<p
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(marketingCopy),
									}}></p>
							</div>
						)}
						{marketingCopy25 && (
							<div className={styles.marketing_stub}>
								<div className={styles.marketing_stub__title}>25 Words</div>
								<p
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(marketingCopy25),
									}}></p>
							</div>
						)}
						{marketingCopy50 && (
							<div className={styles.marketing_stub}>
								<div className={styles.marketing_stub__title}>50 Words</div>
								<p
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(marketingCopy50),
									}}></p>
							</div>
						)}
					</div>
				</div>
			)}
			<div className={productStyles.sub_section}>
				{longDescription && (
					<>
						<div className={productStyles.sub_section__title}>{t("Long Description")}</div>
						<p
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(longDescription),
							}}></p>
					</>
				)}
			</div>
		</section>
	);
}
Marketing.shouldRender = ({ productDetails, lng }) => {
	return getMarketingData(productDetails, lng).isVisible;
};

function getMarketingData(marketingDetails, lng) {
	if (!marketingDetails)
		return {
			marketingContent: {
				headlineCopy: "",
				marketingCopy: "",
				marketingCopy25: "",
				marketingCopy50: "",
				longDescription: "",
			},
			isVisible: false,
		};

	const {
		"10WordsAdvertisingCopy": advertisingCopy10,
		"25WordsAdvertisingCopy": advertisingCopy25,
		"50WordsAdvertisingCopy": advertisingCopy50,
		headline,
		longDescription,
	} = marketingDetails;

	const marketingContent = {
		headlineCopy: headline?.[lng] ?? headline?.["en_US"],
		marketingCopy: advertisingCopy10?.[lng] ?? advertisingCopy10?.["en_US"],
		marketingCopy25: advertisingCopy25?.[lng] ?? advertisingCopy25?.["en_US"],
		marketingCopy50: advertisingCopy50?.[lng] ?? advertisingCopy50?.["en_US"],
		longDescription: longDescription?.[lng] ?? longDescription?.["en_US"],
	};

	const isVisible = Boolean(
		marketingContent.headlineCopy ||
			marketingContent.marketingCopy ||
			marketingContent.marketingCopy25 ||
			marketingContent.marketingCopy50 ||
			marketingContent.longDescription
	);

	return { marketingContent, isVisible };
}

export default Marketing;
