import ProductAssets from "../../component/productDetail/assets/ProductAssets";
import Bullets from "../../component/productDetail/bullets/Bullets";
import Compliance from "../../component/productDetail/compliance/Compliance";
import Dimensions from "../../component/productDetail/dimensions/Dimensions";
import Footnotes from "../../component/productDetail/footnotes/Footnotes";
import KeyFeatures from "../../component/productDetail/keyFeatures/KeyFeatures";
import LegalCopy from "../../component/productDetail/legalCopy/LegalCopy";
import Marketing from "../../component/productDetail/marketing/Marketing";
import ProductGallery from "../../component/productDetail/productGallery/ProductGallery";
import ProductInfo from "../../component/productDetail/ProductInfo";
import RelatedProducts from "../../component/productDetail/relatedProducts/RelatedProducts";
import Specifications from "../../component/productDetail/specifications/Specifications";
import { useTranslation } from "react-i18next";

export default function ProductPage() {
	const { t } = useTranslation();
	return (
		<ProductInfo>
			<ProductGallery id={"gallery"} displayText={"Gallery"} headerLink/>
			<Bullets id={"bullets"} displayText={"Highlights"} headerLink />
			<KeyFeatures id={"keyFeature"} displayText={"Key Features"} headerLink />
			<Marketing id={"marketting"} displayText={t("Ad Copy")} headerLink />
			<Specifications id={"specifications"} displayText={t("Specifications")} headerLink />
			<Dimensions id={"dimensions"} displayText={"Dimensions"} headerLink />
			<ProductAssets id={"assets"} displayText={t("Downloadable Assets")} headerLink />
			<RelatedProducts id={"relatedProduct"} displayText={t("Related Products")} headerLink />
			<Compliance id={"compliance"} displayText={"Compliance"} headerLink />
			<Footnotes id={"footnotes"} displayText={t("Footnotes")} />
			<LegalCopy id={"legalCopy"} displayText={"Legal Copy"} />
		</ProductInfo>
	);
}
