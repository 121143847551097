import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import ms from "ms";

import "./index.css";
import "./i18n.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { authconfig } from "./config.js";


Amplify.configure(authconfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
			refetchOnMount: true, // default : always
			gcTime: ms("1h"),
			staleTime: ms("1h"),
		},
	},
});

const theme = createTheme({
	typography: {
		allVariants: {
			fontFamily: "SSTPro",
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<React.Suspense fallback={<HydrateFallback />}>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</QueryClientProvider>
			</ThemeProvider>
		</React.Suspense>
	</React.StrictMode>
);
function HydrateFallback() {
	return (
		<Box className='progressBox'>
			<CircularProgress className='progressBox__Loader' />
		</Box>
	);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
