import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../store/AppProvider";

/*
Hook to dynamically handle the tooltip position of stickey nav bar
*/
function useToolTip() {
	const toolTipRef = useRef();
	const toolTipParent = useRef();
	const { dynamicMenuItems, staticFilteredMenuItems } = useContext(AppContext);
	useEffect(() => {
		const updateTooltipPosition = () => {
			if (!toolTipParent.current || !toolTipRef.current) return;

			const parentRect = toolTipParent.current.getBoundingClientRect();
			toolTipRef.current.style.top = `${parentRect.top + parentRect.height / 2}px`;
            // toolTipRef.current.style.transform = 'translateY(-50%)';
		};
		window.addEventListener("scroll", updateTooltipPosition);
		window.addEventListener("resize", updateTooltipPosition);

		// Initial position
		updateTooltipPosition();

		return () => {
			window.removeEventListener("scroll", updateTooltipPosition);
			window.removeEventListener("resize", updateTooltipPosition);
		};
	}, [dynamicMenuItems,staticFilteredMenuItems]);
    return { toolTipRef, toolTipParent };
}
export default useToolTip;