import React from "react";
import SearchForm from "../../component/searchPage/SearchForm";
import SearchDropdown from "../../component/searchPage/SearchDropdown";
import SearchInput from "../../component/searchPage/SearchInput";
import { downloadBulletinPDF, getSearchCriteria, getSearchResults } from "../../service/SalesNews";
import { PdfLink } from "../../component/searchPage/PdfLink";
import Download from "../../component/download/Download";
import { useTranslation } from "react-i18next";

function renderLink(params) {
	const { t } = useTranslation();
	const { bulletin_no, link } = params.value;
	return (
		<PdfLink href={`${link}`} tabIndex={params.tabIndex}>
			{bulletin_no}
		</PdfLink>
	);
}


const columns = (t) => [
	{ field: "bulletin_no", headerName: t("Sales News No"), width: 170, renderCell: renderLink, valueFormatter: (value) => `${value.bulletin_no}` },
	{ field: "description", headerName: t("Description"), minWidth: 300, flex: 1 },
	{ field: "bulletin_type", headerName: t("Type"), width: 200 },
	{ field: "promo_start_dt", headerName: t("Effective Date"), width: 150 },
	{ field: "bulletin_dt", headerName: "Email Date", width: 150, sort: "desc" },
];
//TODO implement excel download
function SalesNews() {
	const { t } = useTranslation();
	return (
		<>
		<SearchForm
			title='Sales News'
			requiredInputs={1}
			columns={columns(t)}
			criteriaFn={getSearchCriteria}
			searchFn={getSearchResults}
			downloadFns={{title:'SalesNews', zip: downloadBulletinPDF, excel: () => {} }}>
			<SearchDropdown label='Model Name' dataLabel='models' />
			<SearchDropdown label={t('Sales News No')} dataLabel='salesNewsNos' />
			<SearchDropdown label={t('Type')} dataLabel='salesNewsTypes' />
			{/* <SearchDropdown label='Email Period' dataLabel='bulletinDt' /> */}
			<SearchDropdown label={t('Effective Period')} dataLabel='promoStartDt' />
			<SearchInput label={t('Description')} dataLabel='descSearch' />
		</SearchForm>
		</>
	);
}

export default SalesNews;
