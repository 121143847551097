import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";
import { SCHEDULE_TEMPLATE_TYPE } from "../utils/constants";

const privateAxios = AxiosInstancePrivate();


export const fetchCurrentSchedules = async (contractNumber) => {
    try {
        const res = await privateAxios.get(urls.amendSchedules, {
            params: { contract_number: contractNumber },
        });
        console.log("fetchCurrentSchedules response:::::::", res);
        return res.data;
    } catch (err) {
        throw new Error('Failed to load contract templates. Please try again later.');
    }
};

export const fetchScheduleTemplates = async (contractNumber) => {
    try {
        const res = await privateAxios.get(urls.manageTemplates, {
            params: { type: SCHEDULE_TEMPLATE_TYPE, contract_number: contractNumber },
        });
        console.log("fetchTemplates response:::::::", res);
        return res.data;
    } catch (err) {
        throw new Error('Failed to load contract templates. Please try again later.');
    }
};

export const amendSchedules = async (contractNumber, schedules) => {
    console.log("contractNumber, schedules:::::::", contractNumber, schedules)
    try {
        const response = await privateAxios.put(urls.amendSchedules, {
            contract_number: contractNumber, schedule_numbers: schedules
        });
        console.log("response::::::::", response)
        return response;
    } catch (error) {
        console.error('Error amending schedules:', error);
        throw error;
    }
};


// export const fetchCurrentSchedules = async (contractNumber) => {
//     // Dummy data for current schedules
//     return [
//         { id: 1, scheduleNumber: 'SCH001', scheduleName: 'Schedule 1' },
//         { id: 2, scheduleNumber: 'SCH002', scheduleName: 'Schedule 2' },
//     ];
// };

// export const fetchScheduleTemplates = async () => {
//     // Dummy data for schedule templates
//     return [
//         { id: 1, scheduleNumber: 'TEMPLATE001', scheduleName: 'Template 1', prorateAmount: 0 },
//         { id: 2, scheduleNumber: 'TEMPLATE002', scheduleName: 'Template 2', prorateAmount: 0 },
//     ];
// };