import DOMPurify from "dompurify";
import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./bullets.module.css";
import productStyles from "../productDetails.module.css";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getFootnotesData } from "../footnotes/Footnotes";
import { getLegalCopyData } from "../legalCopy/LegalCopy";

function Bullets() {
	const { t } = useTranslation();
	const {
		productDetails: { shortBullets, longBullets, legalCopy, footnotes },
		lng,
	} = useProductDetails();

	const { footnotesString } = useMemo(() => getFootnotesData(footnotes, lng), [footnotes, lng]);
	const { legalCopyString } = useMemo(() => getLegalCopyData(legalCopy, lng), [legalCopy, lng]);
	const { shortBulletsString, longBulletsString } = useMemo(() => getBulletsData(shortBullets, longBullets, lng), [shortBullets, longBullets, lng]);

	return (
		<section className={`${productStyles.section} ${styles.bullets}`}>
			<div className={`${productStyles.title} `}>Highlights</div>
			{shortBulletsString && (
				<div className={productStyles.sub_section}>
					<div className={productStyles.sub_section__title}>Short</div>
					<p
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(shortBulletsString),
						}}></p>
				</div>
			)}
			{longBulletsString && (
				<div className={productStyles.sub_section}>
					<div className={productStyles.sub_section__title}>Long</div>
					<p
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(longBulletsString),
						}}></p>
				</div>
			)}
			<div className={styles.section__footer}>
				<p>
					Reproduction in whole or in part without written permission is prohibited. Features and Specifications are subject to change without notice.
					The values for mass and dimension are approximate. All tradmarks are the property of their respective owners.
					<sup>*</sup>Logo mentions need to be included if logo shown or listed in copy.
					{footnotesString && <a href='#footnotes'>{t("Footnotes")}</a>} {legalCopyString && <a href='#legalCopy'>Legal Copy</a>}
				</p>
			</div>
		</section>
	);
}
Bullets.shouldRender = ({ productDetails: { shortBullets, longBullets }, lng }) => {
	return getBulletsData(shortBullets, longBullets, lng).isVisible;
};

function getBulletsData(shortBullets, longBullets, lng) {
	if (!shortBullets && !longBullets)
		return {
			shortBulletsString: "",
			longBulletsString: "",
			isVisible: false,
		};

	const shortBulletsString = shortBullets?.[lng] ?? shortBullets?.en_US ?? "";
	const longBulletsString = longBullets?.[lng] ?? longBullets?.en_US ?? "";

	return {
		shortBulletsString,
		longBulletsString,
		isVisible: Boolean(shortBulletsString || longBulletsString),
	};
}
export default Bullets;
