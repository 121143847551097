import { Link, useLocation} from "react-router-dom";

import Catalog from "../../component/catalog/Catalog"
import styles from "./productCatalog.module.css"
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export default function ProductCatalog(){
	useDocumentTitle("Product Catalog")
    return <Catalog RenderCards={RenderCards}/>
}

function RenderCards({data}){
	const { pathname } = useLocation();
	return <div className='catalog__body--list'>
	{data?.list &&
		Object.entries(data.list).map(([title, details]) => {
			const path = pathname;
			return (
				<ProductCard
					key={title}
					data={{
						title,
						...details,
					}}
					path={path}
					isProduct={data?.isProduct}
					isAsset={data?.isAsset}
				/>
			);
		})}
		</div>
}

function ProductCard({ data, path, isProduct }) {
	const { title, route, imageUrl } = data;
	return (
		<Link  title={title} to={isProduct ? `/product/${route}` : `${path}/${route}`} className={styles.category__card}>
			<div className={styles['category__card--img']}>
				<img src={imageUrl}  loading='eager' />
			</div>
			<div className={`horizontal-ruler ${styles['category__card--ruler']}`} />
			<div className={styles['category__card--title']}>
				<h3>{title}</h3>
			</div>
		</Link>
	);
}