import { useContext, useState } from "react";
const { createContext } = require("react");

const productContext = createContext();

export const useProductDetails = () => {
	const context = useContext(productContext);
	if (!context) {
		throw new Error("Must be a Child of ProductProvider");
	}
	return context;
};

export default function ProductProvider({ children, productDetails, lng, assetData, setAssetPopUpId,derivedAssetType}) {
	return <productContext.Provider value={{ productDetails, lng, assetData, setAssetPopUpId,derivedAssetType }}>{children}</productContext.Provider>;
}
