import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useId } from "react";
import { useSearchFormContext } from "./SearchForm";
import { useController } from "react-hook-form";

function SearchDropdown({ label, dataLabel }) {
	const { control, errors, criteriaData } = useSearchFormContext();
	const {
		field,
		fieldState: { invalid, isTouched, isDirty },
		formState: { touchedFields, dirtyFields },
	} = useController({ control, name: dataLabel, defaultValue: null });
	const options = criteriaData?.[dataLabel] ?criteriaData?.[dataLabel]: [];
	// console.log(options);
	return (
		<div className='searchForm'>
			<label htmlFor={dataLabel}>{label}</label>

			<Autocomplete
				disablePortal
				id={dataLabel}
				isOptionEqualToValue={(option, value) => option?.value === value?.value}
				options={options}
				sx={{ width: 300 }}
				onChange={(e, v) => {
					field.onChange(v);
				}}
				value={field.value}
				getOptionLabel={(option)=>option?.value}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={label}
						error={Boolean(errors[dataLabel])}
						onBlur={field.onBlur}
						name={field.name}
						sx={{ backgroundColor: "white" }}
						size='small'
					/>
				)}
			/>
		</div>
	);
}

export default SearchDropdown;
