const { AxiosInstancePrivate } = require("../utils/AxiosInstance");
import { urls } from "../config";

const privateAxios = AxiosInstancePrivate();

export async function getAssetDetails(pimcore_id) {
    const res= await privateAxios.get(urls.assets, { params: { pimcore_id }});
    return res.data;
}
export async function downloadAssetFromBE(asset_uri,pimcore_id) {
    const res= await privateAxios.post(urls.downloadAsset,{asset_uri,pimcore_id});
    return res.data;
}
export async function getCatalog(lang){
    const res= await privateAxios.get(urls.catalog,{params:{lang}});
    return res.data;
}
export async function getSubCatalog(subCategoryUri,lang){
    const res= await privateAxios.get(urls.catalog,{params:{subCategoryUri,lang}});
    return res.data;
}
export async function getProduct(productUri){
    const res= await privateAxios.get(urls.product, { params: { productUri }});
    return res.data;
}
export async function getProductAssets(productUri){
    const res= await privateAxios.get(urls.assets, { params: { productUri }});
    return res.data;
}
export async function emailAsset(pimcore_id,isProduct,email_ids){
    const res= await privateAxios.post(urls.assets,{pimcore_id,isProduct,email_ids});
    return res.data;
}
export async function expiryAssets(paginationModel){
    const res= await privateAxios.post(urls.expiryAssets,{paginationModel});
    return res.data;
}