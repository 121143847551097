import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BULLETIN_TOKEN, REDIRECT_PATH } from "../../utils/constants";
import { signInWithRedirect } from "aws-amplify/auth";
import { FullPageLoading } from "../../component/callback/Callback";
import { useQuery } from "@tanstack/react-query";
import { getSalesNewsMailLink, getSalesNewsMailLinkAuth } from "../../service/SalesNews";
import useUser from "../../hooks/useUser";
import ErrorHandler from "../../component/errors/ErrorHandler";

const ViewBulletin = () => {
	// const [loading, setLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const token = new URLSearchParams(window.location.search).get("token");
	const loc = useLocation();
	const { isLoggedIn } = useUser();
	const {
		data: pdfLink,
		error: salesNewsError,
		isFetching,
	} = useQuery({
		enabled: !isLoggedIn,
		queryKey: [token, "withoutAuth"],
		queryFn: async () => getSalesNewsMailLink(token),
		retry: (failureCount, error) => {

			if (error?.response?.status === 401 && error?.response?.data?.message?.name ==="TokenExpiredError") {
				return false;
			}
			return failureCount < 3;
		},
	});
	const {
		data: pdfLinkAuth,
		error: salesNewsAuthError,
		isFetchingAuth,
	} = useQuery({
		enabled: isLoggedIn,
		queryKey: [token, "Auth"],
		queryFn: async () => getSalesNewsMailLinkAuth(token),
		retry: (failureCount, error) => {
			if (error?.response?.status === 401 && error?.response?.data?.message?.name ==="TokenExpiredError") {
				return false;
			}
			return failureCount < 3;
		},
	});

	useEffect(() => {
		if (Boolean(pdfLink)) {
			const { redirectUrl } = pdfLink;
			if (!redirectUrl) {
				localStorage.setItem(BULLETIN_TOKEN, token);
				localStorage.setItem(REDIRECT_PATH, loc.pathname);
				signInWithRedirect();
			} else {
				window.location.href = redirectUrl;
			}
		}
	}, [pdfLink]);

	useEffect(() => {
		if (Boolean(pdfLinkAuth)) {
			const { redirectUrl } = pdfLinkAuth;
			if (!redirectUrl) {
				setIsError(true);
			} else {
				window.location.href = redirectUrl;
			}
		}
	}, [pdfLinkAuth]);

	
	if (salesNewsError?.response?.data?.message?.name ==="TokenExpiredError" || salesNewsAuthError?.response?.data?.message?.name ==="TokenExpiredError") {
		return <ErrorHandler title={"Bulletin Expired"} desc={"Unable to Load Bulletin"} />;
	}
	if (isError || salesNewsError || salesNewsAuthError) {
		return <ErrorHandler title={"Something is wrong"} desc={"Unable to Load Bulletin"} />;
	}

	return <FullPageLoading />;
};

export default ViewBulletin;
