import { useContext } from "react";
import { AppContext } from "../store/AppProvider";

function useUser() {
	const { userState } = useContext(AppContext);
	const isLoggedIn = userState?.loggedIn;
	const isImpersonated = userState?.impersonated;
	const activeUser = userState.impersonated ? userState.impersonatedUser : userState.cognitoUser;
	const activeRoles = userState?.sapUserRole ? userState.sapUserRole : userState.impersonated ? userState.impersonatedRoles : userState.cognitoRoles;
	const activeAccount = userState.impersonated ? userState.impersonatedAccountObj : null;
	const activeContact = userState.impersonated ? userState.impersonateContactObj : null;
	const activeUserType = userState.impersonated ? "B2B" : userState?.userType;
	const activeCustomerGroup = userState.impersonated ? userState?.impersonatedCustomerGrp : userState?.customerGroup;
	const activeUserEmail = userState.impersonated ? userState.impersonatedUser.email : userState.cognitoUser.email;
	const cognitoRoles = userState.cognitoRoles;
	return {
		isLoggedIn,
		isImpersonated,
		activeUser,
		activeRoles,
		activeAccount,
		activeContact,
		activeUserType,
		activeCustomerGroup,
		activeUserEmail,
		cognitoRoles,
	};
}

export default useUser;
