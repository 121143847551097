import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./EditScheduleTemplate.css";
import { updateTemplate } from "../../../../service/contractTemplate";
import { SCHEDULE_TEMPLATE_TYPE } from "../../../../utils/constants";

const EditScheduleTemplate = ({ template, onClose, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(false);
	// console.log("template to edit:::::::", template);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			scheduleNumber: template?.id || "",
			prorateAmount: template?.pro_rate_amount || "",
			id: template?.id || null,
			title: template?.pdf_schedule_name?.pdf_schedule_name || "",
		},
	});
	const [error, setError] = useState(null);

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			// console.log("Editing sch template:::::",template,data);
			await updateTemplate(template.id, data, SCHEDULE_TEMPLATE_TYPE);
			onSuccess();
			onClose();
		} catch (err) {
			setError("Failed to update the contract template. Please try again later.");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='modal'>
			<div className='modal-content'>
				<h2>Update Schedule Template</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='input-group'>
						<label htmlFor='scheduleNumber'>Schedule #</label>
						<input id='scheduleNumber' type='text' {...register("scheduleNumber")} required disabled />
					</div>
					<div className='input-group'>
						<label htmlFor='prorateAmount'>Prorate Amount</label>
						<input id='prorateAmount' type='text' {...register("prorateAmount")} disabled />
					</div>
					<div className='input-group'>
						<label htmlFor='title'>Template Name</label>
						<input id='title' type='text' {...register("title")} disabled />
					</div>

					<div className='input-group'>
						<label htmlFor='file'>File</label>
						<input id='file' type='file' {...register("file")} required />
					</div>

					{error && <div className='error-message'>{error}</div>}

					<div className='modal-actions'>
						<button type='submit' className='submit-btn' disabled={isLoading}>
							Submit
						</button>
						<button type='button' className='close-btn' onClick={onClose} disabled={isLoading}>
							Close
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EditScheduleTemplate;
