import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";

const privateAxios = AxiosInstancePrivate();

export async function getUsersList(soldto_number) {
	const res = await privateAxios.get(urls.userList, { params: { soldToNumber: soldto_number } });
	return res.data;
}

export async function getUserRoles(soldto_number, sap_contact_id) {
		const res = await privateAxios.get(urls.getUserRoles, { params: { soldto_number: soldto_number, sap_contact_id: sap_contact_id } });
		return res.data;
}