import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress } from '@mui/material';
import { fetchContracts, acceptContract, rejectContract } from '../../service/adminService';
import './ContractManagementPage.css';
import { PdfLink } from '../../component/searchPage/PdfLink';
import { PageLoader } from "../../component/loader/PLoader";

const ContractManagementPage = () => {
  const [agreements, setAgreements] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [actionType, setActionType] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });
  const [formData, setFormData] = useState({ name: '', title: '', reason: '' });
  const [errors, setErrors] = useState({ name: '', title: '', reason: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const loadContracts = async () => {
    setIsLoading(true);
    try {
      const data = await fetchContracts("0001001838");
      setAgreements(data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to load contracts.', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadContracts();
  }, []);

  const handleActionClick = (agreement, action) => {
    setSelectedAgreement(agreement);
    setActionType(action);
    setFormData({ name: '', title: '', reason: '' }); // Reset form data
    setErrors({ name: '', title: '', reason: '' }); // Reset errors
    setModalOpen(true);
  };

  const validateForm = () => {
    let valid = true;
    let errors = { name: '', title: '', reason: '' };

    if (actionType === 'accept') {
      if (!formData.name.match(/^[a-zA-Z\s]+$/)) {
        errors.name = 'Name can only contain letters and spaces.';
        valid = false;
      }

      if (!formData.title) {
        errors.title = 'Title is required.';
        valid = false;
      }
    } else if (actionType === 'reject') {
      if (!formData.reason) {
        errors.reason = 'Rejection reason is required.';
        valid = false;
      }
    }

    setErrors(errors);
    return valid;
  };

  const handleConfirmAction = async () => {
    if (!validateForm()) {
      return;
    }

    setIsActionLoading(true);
    try {
      if (actionType === 'accept') {
        await acceptContract(selectedAgreement, formData);
      } else {
        await rejectContract(selectedAgreement, formData);
      }
      setSnackbar({ open: true, message: `Agreement ${actionType}ed successfully!`, type: 'success' });
      loadContracts(); // Refetch contracts on success
    } catch (error) {
      setSnackbar({ open: true, message: 'Something went wrong. Please try again.', type: 'error' });
    } finally {
      setIsActionLoading(false);
    }
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => setSnackbar({ open: false, message: '', type: '' });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  function renderLink(params) {
    const { pdf_doc_name, link } = params.value ?? '';
    return (
      <PdfLink href={`${link}`} tabIndex={params.tabIndex}>
        {pdf_doc_name}
      </PdfLink>
    );
  }

  const columns = [
    { field: 'pdf_doc_name', headerName: 'Agreement Document', flex: 2, renderCell: renderLink, valueFormatter: (value) => value.pdf_doc_name },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'acceptance_year', headerName: 'Year', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div className="action-icons">
          <CheckCircleIcon
            className="accept-icon"
            onClick={() => handleActionClick(params.row, 'accept')}
            style={{ color: params.row.status === 'Pending User Review' && params.row.lock_status=== false ? 'green' : 'gray', pointerEvents: params.row.status === 'Pending User Review' && params.row.lock_status=== false ? 'auto' : 'none' }}
          />
          <CancelIcon
            className="reject-icon"
            onClick={() => handleActionClick(params.row, 'reject')}
            style={{ color: params.row.status === 'Pending User Review' && params.row.lock_status=== false ? 'red' : 'gray', pointerEvents: params.row.status === 'Pending User Review' && params.row.lock_status=== false  ? 'auto' : 'none' }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <PageLoader show={isLoading} />
      <h1 className="page-heading">Sales Agreements</h1>
      <hr className="horizontal-ruler" />
      <p className="instructions">
        Click on the sales agreement to view in .pdf format. You can download the documents via the .pdf viewer.<br />
        <strong>Note:</strong> You cannot change an agreement once it has been accepted or rejected.
      </p>

      <h2 className="subheading">List of Agreements</h2>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={agreements} columns={columns} pageSize={5} />
      </div>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth className={isActionLoading ? 'modal-disabled' : ''}>
        <DialogTitle>{actionType === 'accept' ? 'Accept Agreement' : 'Reject Agreement'}</DialogTitle>
        <DialogContent>
          {isActionLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {actionType === 'accept' && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    value={formData.name}
                    onChange={handleFormChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    margin="dense"
                    name="title"
                    label="Title"
                    type="text"
                    fullWidth
                    required
                    value={formData.title}
                    onChange={handleFormChange}
                    error={!!errors.title}
                    helperText={errors.title}
                  />
                </>
              )}
              {actionType === 'reject' && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="reason"
                    label="Rejection Reason"
                    type="text"
                    fullWidth
                    required
                    value={formData.reason}
                    onChange={handleFormChange}
                    error={!!errors.reason}
                    helperText={errors.reason}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary" style={{ marginRight: 'auto' }} disabled={isActionLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary" disabled={isActionLoading}>
            {actionType === 'accept' ? 'Submit' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        className={snackbar.type === 'success' ? 'snackbar-success' : 'snackbar-error'}
      />
    </div>
  );
};

export default ContractManagementPage;