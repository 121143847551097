import axios from "axios";
import { API } from "../config";
import { ACCESS_TOKEN, ID_TOKEN, IMPERSONATED_JWT } from "./constants";
import { fetchAuthSession } from "@aws-amplify/auth";

const AxiosInstancePrivate = () => {
	const axiosIns = axios.create({
		baseURL: API,
		// withCredentials: true,
	});
//TODO IMPERSONNATION token expire
//TODO AUTO logout based on other tab sessions
	axiosIns.interceptors.request.use(
		(config) => {
			
			config.headers = {
				...config.headers,
				"authorization": sessionStorage.getItem(ID_TOKEN),
				"impersonate": sessionStorage.getItem(IMPERSONATED_JWT),
			};
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	axiosIns.interceptors.response.use(
		(res) => res,
		async (error) => {
			const originalRequest = error.config;
			if (error?.response?.status === 401 && error?.response?.data?.message === "The incoming token has expired" && !originalRequest._retry) {
				originalRequest._retry = true;
				try {
					console.log("Token has expired, refreshing session")
					// Get new tokens
					const response = await fetchAuthSession({ forceRefresh: true });
					const payld = response.tokens;
					// Update session storage
					sessionStorage.setItem(ACCESS_TOKEN, payld.accessToken.toString());
					sessionStorage.setItem(ID_TOKEN, payld.idToken.toString());

					// Update the Authorization header with new token
					originalRequest.headers["authorization"] = payld.idToken.toString();
					
					// Retry the original request with new token
					return axiosIns(originalRequest);
				} catch (refreshError) {
					return Promise.reject(refreshError);
				}
			}
			return Promise.reject(error);
		}
	);

	return axiosIns;
};

const AxiosInstancePublic = () => {
	const axiosIns = axios.create({
		baseURL: API,
		headers: {},
	});

	return axiosIns;
};

export { AxiosInstancePublic, AxiosInstancePrivate };
