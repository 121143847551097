import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AddContractTemplate from './AddContractTemplate';
import EditContractTemplate from './EditContractTemplate';
import { fetchTemplates, deleteTemplate } from '../../../../service/contractTemplate';
import ConfirmationModal from './ConfirmationModal'; 
import { Edit, Delete } from '@mui/icons-material'; 
import './ManageContractTemplates.css';
import { PdfLink } from '../../../../component/searchPage/PdfLink';
import { CONTRACT_TEMPLATE_TYPE } from '../../../../utils/constants';
import { useTranslation } from "react-i18next";
import { PageLoader } from "../../../../component/loader/PLoader";

const ManageContractTemplates = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([
  ]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [deleteTemplateId, setDeleteTemplateId] = useState(null); 
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    try {
      setIsLoading(true);
      const data = await fetchTemplates(CONTRACT_TEMPLATE_TYPE);
      console.log("data.rows::::::",data?.rows)
      setTemplates(data?.rows);
      setError(null); 
    } catch (err) {
      setError('Failed to load contract templates. Please try again later.');
    }finally{
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteTemplate(deleteTemplateId,CONTRACT_TEMPLATE_TYPE);
      loadTemplates(); 
      setIsDeleteModalOpen(false); 
    } catch (err) {
      setError('Failed to delete the template. Please try again later.');
      setIsDeleteModalOpen(false); 
    }finally{
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteTemplateId(id); 
    setIsDeleteModalOpen(true); 
  };
  function renderLink(params) {
    const {boiler_pdf_name,link} = params.value ?? '';
    return (
      <PdfLink href={`${link}`} tabIndex={params.tabIndex}>
      {boiler_pdf_name}
      </PdfLink>
    );
    }
  const columns = [
    { field: 'id', headerName: 'Template ID', flex: 0.5},
    { field: 'boiler_pdf_name', headerName: 'Template Name', flex: 1 ,renderCell:renderLink,valueFormatter: (value) =>  value.boiler_pdf_name},
    { field: 'contract_type', headerName: t("Contract Type"), flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <div className="actions">
          <button className="edit-btn" onClick={() => setEditTemplate(params.row)}>
            <Edit fontSize="small" />
          </button>
          <button className="delete-btn" onClick={() => handleDeleteClick(params.row.id)}>
            <Delete fontSize="small" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="manage-contract-templates">
      <PageLoader show={isLoading} />
      <h1 className='page-heading'>Manage Contract Templates</h1>
      <hr className='horizontal-ruler'/>
      {/* {error && <div className="error-message">{error}</div>} */}
      <button className="add-new-file-btn" onClick={() => setIsAddModalOpen(true)}>{t("Add New File")}</button>
      <div className="data-grid-container">
        <DataGrid rows={templates} columns={columns} pageSize={5} />
      </div>
      {isAddModalOpen && <AddContractTemplate onClose={() => setIsAddModalOpen(false)} onSuccess={loadTemplates} />}
      {editTemplate && <EditContractTemplate template={editTemplate} onClose={() => setEditTemplate(null)} onSuccess={loadTemplates} />}
      
      {isDeleteModalOpen && (
        <ConfirmationModal 
          message="Are you sure you want to delete this template?" 
          onConfirm={handleDelete} 
          onCancel={() => setIsDeleteModalOpen(false)}
          isLoading={isLoading} 
        />
      )}
    </div>
  );
};

export default ManageContractTemplates;
