import { Outlet } from "react-router-dom";
import "./Layout.css"
import Navigation from "../navigation/Navigation";
import useUser from "../../hooks/useUser";


//TEST GLOBAL SEARCH CPG
//TODO implement un auth page
//TODO check auto logout
//TODO add scroll to assets
//TODO dont not retry if key does not exist, useAsset

//TODO DOWNLAOD for contracts and excel
//TODO INVALID cache at job timing
//TODO Error Notifications

//TODO ACTIVE filter for assets and Contracts
//TODO WCAG SPECIFICATION TAB
//TODO WCAG Global Search
//TODO remove role matric from public

//TODO handle conditions for null from s3 in FE

export default function Layout({sideNav: isOpen, setSideNav: setSideNavState, gbSearch: gbSearch }) {
    const {isLoggedIn}=useUser();
    
    return (
        <main id="layoutDiv" className={isLoggedIn?"mainLayout":"mainLayout login"} >
            <section style={isLoggedIn?{}:{'display':"none"}} className={isOpen?"mainLayout__NavBar active":"mainLayout__NavBar"} >
                <Navigation isOpen={isOpen} toggleDrawer={setSideNavState} gbSearch={gbSearch} />
                <div className={isOpen?"backdrop backdrop--show":"backdrop"} style={{position:"absolute"}} onClick={() => { setSideNavState(false) }} ></div>
            </section>
            <section className="mainLayout__Outlet" >
                <Outlet />
            </section>
        </main>
    )
}