import { TextField } from '@mui/material'
import React from 'react'
import { useSearchFormContext } from './SearchForm'

function SearchInput({label,dataLabel}) {
  const {register,errors}=useSearchFormContext()
  return (

   <div className='searchForm'>
     <label htmlFor={dataLabel}>{label}</label>
     <TextField autoComplete='off' id={dataLabel} {...register(dataLabel)} error={Boolean(errors[dataLabel])} size="small" variant="outlined" sx={{backgroundColor:"white"}}/>
   </div>
  )
}

export default SearchInput