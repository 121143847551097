import { useQuery } from "@tanstack/react-query";
import { getCatalog } from "../../service/productsService";
import styles from "./MoreAssets.module.css";
import { PageLoader } from "../../component/loader/PLoader";
import Heading from "../../component/heading/Heading";
import ErrorHandler from "../../component/errors/ErrorHandler";
import { LogoCard } from "../../pages/logoCatalog/LogoCatalog";

export default function MoreAssets({dataKey,title}) {
	const {
		isFetching,
		data: dynamData,
		error,
	} = useQuery({
		queryKey: ["dynamicItems"],
		queryFn: async () => getCatalog(),
	});

    //TODO update the asset in cache
	if (error) {
		return <ErrorHandler title={"Something Went Wrong"} desc={"Sorry, the required data could not be fetched."} />;
	}

	const itemsObj = dynamData && dynamData[dataKey];
	const isEmpty = itemsObj && Object.keys(itemsObj).length === 0;

	if(isEmpty){
		return <ErrorHandler title={"Data not found"} desc={"Sorry, the required data could not be fetched."} />;
	
	}
	return (
		<div className={`container ${styles["moreAssets__container"]}`}>
			<PageLoader show={isFetching} />
			<Heading title={title} />
			<div className={styles["moreAssets--list"]} style={{ flexDirection: "column" }} role="list" aria-busy={isFetching}>
				{!isEmpty && itemsObj && Object.entries(itemsObj).map(([key,item]) => <LogoCard key={key} data={{ title: "", ...item }} isAsset={true} hasBackground={false} />)}
			</div>
		</div>
	);
}
