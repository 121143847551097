import { useEffect } from "react";

function useEscapeKey(handler, active = true) {
	useEffect(() => {
		const handleEscKey = (event) => {
			if (event.key === "Escape") {
				handler();
			}
		};
		if (active) {
			document.addEventListener("keydown", handleEscKey);
		}else{
            document.removeEventListener("keydown", handleEscKey);
        }
		return () => {
			document.removeEventListener("keydown", handleEscKey);
		};
	}, [handler, active]);
}

export default useEscapeKey;
