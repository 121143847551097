export const SET_ERROR='set_error'
export const CLEAR_ERROR='clear_error'


export const errorReducer = (state, { action, payload }) => {
    switch (action) {
      case SET_ERROR:
        return { ...payload };
      case CLEAR_ERROR:
        return { };
      default:
        return state;
    }
  };