import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarIcon from "@mui/icons-material/Star";
import { produce } from "immer";

export const SET_CATALOG_SUBMENUS = "SET_CATALOG_SUBMENUS";
export const SET_LOGOS_SUBMENUS = "SET_LOGOS_SUBMENUS";


export const setCatalogSubmenus = (submenus) => ({
	type: SET_CATALOG_SUBMENUS,
	payload: submenus,
});

export const setLogosSubmenus = (submenus) => ({
	type: SET_LOGOS_SUBMENUS,
	payload: submenus,
});


const ROUTE_LOGOS = "logos";
const ROUTE_CATALOG = "catalog";
const logoFilter = (item) => item.route === ROUTE_LOGOS ;
const catalogFilter = (item) => item.route === ROUTE_CATALOG;

const BASE_CATALOG = Object.freeze({
	route: ROUTE_CATALOG,
	title: "Catalog",
	icon: <MenuBookIcon />,
	subMenus: {},
});
const BASE_LOGO = Object.freeze({
	route: ROUTE_LOGOS,
	title: "Logos",
	icon: <StarIcon />,
	subMenus: {},
});

const DEFAULT_ITEMS = [
	{
		route: "catalog",
		title: "Catalog",
		icon: <MenuBookIcon />,
		subMenus: {
			"Consumer Products": {
				route: "consumer",
				items: {
					"All Accessories": { route: "all-accessories", level_code: "00:01:00" },
					"5G IOT": { route: "5g-iot", level_code: "00:01:00" },
				},
			},
			"Professional Products": {
				route: "pro",
				items: {
					"Archiving & Con": { route: "archiving", level_code: "00:01:00" },
					"Broadcast & Production": { route: "broadcast", level_code: "00:01:00" },
					Medical: { route: "medical", level_code: "00:01:00" },
					"Ariving & Con": { route: "archiing", level_code: "00:01:00" },
					"Broaast & Production": { route: "brodcast", level_code: "00:01:00" },
					Medica: { route: "medcal", level_code: "00:01:00" },
				},
			},
		},
		subMenus: {
			"All Accessories": { route: "all-accessories", level_code: "00:01:00" },
			"5G IOT": { route: "5g-iot", level_code: "00:01:00" },
			"Archiving & Con": { route: "archiving", level_code: "00:01:00" },
			"Broadcast & Production": { route: "broadcast", level_code: "00:01:00" },
			Audio: { route: "audio", level_code: "03" },
		},
	},
	{
		route: "logos",
		title: "Logos",
		icon: <StarIcon />,
		subMenus: {
			"Alpha Logos": {
				route: "alpha-logos",
				level_code: "00:01:00",
			},
			Logos: {
				route: "logos",
				level_code: "00:01:00",
			},
			"Sony Authorized Dealer Logos": {
				route: "sony-authorized-dealer-logos",
				level_code: "00:01:00",
			},
		},
	},
];


const dynamicMenuItemReducer = produce((draft, { payload, type }) => {
	let filtered
	switch (type) {
		case SET_CATALOG_SUBMENUS:
			 filtered = draft.filter(logoFilter);
			draft = [{ ...BASE_CATALOG, subMenus: payload }, ...filtered];
			break;
		case SET_LOGOS_SUBMENUS: {
			filtered = draft.filter(catalogFilter);
			draft = [ ...filtered,{ ...BASE_LOGO, subMenus: payload }];
			break;
		}
		default:
			break;
	}

	return draft;
});


export default dynamicMenuItemReducer;
