import { useProductDetails } from "../productContext/ProductProvider";
import productStyles from "../productDetails.module.css";
import styles from "./productAssets.module.css";
import { camilCaseToSentence, titleCase } from "../../../utils/Utils";
import { Download, FileOpen, ImageNotSupported, PictureAsPdf } from "@mui/icons-material";
import { useMemo, useRef, useState } from "react";
import { downloadAsset } from "../../assetPopUp/AssetPopup";
import useAsset from "../../../hooks/useAsset";
import PDFIcon from "../../../assets/img/icons/pdf.png";
const IGNORE_ASSETS = ["Compliance Assets"];
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";

const CUSTOM_ORDER = {
	"Product Shot": 1,
	"Sample Image": 6,
	"POP Image": 7,
	"Video": 8,
	"Preview Image": 9,
	"Specification Sheet": Infinity,
};

function ProductAssets() {
	const { t } = useTranslation();
	const { derivedAssetType, assetData } = useProductDetails();

	const orderedKeys = useMemo(() => {
		if (!derivedAssetType) return null;
		const validKeys = Object.keys(derivedAssetType).filter((key) => {
			return Array.isArray(derivedAssetType[key]) && derivedAssetType[key].length > 0 && !IGNORE_ASSETS.includes(key);
		});

		return validKeys.sort((a, b) => {
			const orderA = CUSTOM_ORDER[a] || 1000;
			const orderB = CUSTOM_ORDER[b] || 1000;
			return orderA - orderB;
		});
	}, [derivedAssetType, IGNORE_ASSETS, CUSTOM_ORDER]);

	if (!derivedAssetType) {
		return <></>;
	}
	return (
		<section className={`${styles.productAssets} ${productStyles.section}`}>
			<div className={`${productStyles.title}`}>{t("Downloadable Assets")}</div>
			<div className={styles.assets__container}>
				{orderedKeys &&
					orderedKeys.map((key) => {
						const title = key;
						const items = derivedAssetType[key];
						const itemObj = items.reduce((acc, itemId) => {
							if (!assetData?.[itemId]) return acc;
							acc.push({
								itemId,
								assetData: assetData[itemId],
							});
							return acc;
						}, []);
						if (itemObj.length === 0) return null;
						return <AssetTypeSection key={title} title={title} items={itemObj} />;
					})}
			</div>
		</section>
	);
}
ProductAssets.shouldRender = ({ derivedAssetType, assetData }) => {
	return assetData && typeof assetData === "object" && Object.keys(assetData).length !==0 && derivedAssetType && typeof derivedAssetType === "object" && Object.keys(derivedAssetType).length !==0 ;
};
export default ProductAssets;

function AssetTypeSection({ title, items }) {
	const titleC = titleCase(camilCaseToSentence(title));

	return (
		<section className={styles.assetsTypeSubSection}>
			<h3 className={styles.subSectionTitle}>{titleC}</h3>
			<div className={styles.subSectionContent}>
				{items.map((item) => {
					const type = item.assetData?.mimeType;
					switch (type) {
						case "application/pdf":
							return <PDFAssetItem key={item.itemId} item={item} />;
						case "video/mp4":
							return <VideoAssetItem key={item.itemId} item={item} />;
						default:
							return <ImageAssetItem key={item.itemId} item={item} />;
					}
				})}
			</div>
		</section>
	);
}

function ImageAssetItem({ item }) {
	const { itemId, assetData } = item;
	const isImage = assetData?.mimeType.startsWith("image") || assetData?.extension === "eps";

	return (
		<div pimcore_id={itemId} className={styles.subSectionItem}>
			{isImage ? (
				<img className={styles.subSectionItem__child} src={assetData.largeAssetPath} alt={assetData.fileName} loading='lazy' />
			) : (
				<div className={styles.unSupportedType}>
					<ImageNotSupported sx={{ fontSize: 60 }} />
				</div>
			)}
			<HoverDetails id={itemId} />
		</div>
	);
}

export function PDFAssetItem({ item }) {
	const { itemId } = item;

	return (
		<div pimcore_id={itemId} className={styles.subSectionItem}>
			<div className={styles.unSupportedType}>
				{/* <PictureAsPdf sx={{ fontSize: 100 }} />
				 */}
				<img src={PDFIcon} alt='pdf' loading='lazy' style={{height:"130px",width:"auto"}}/>
			</div>
			<HoverDetails id={itemId} />
		</div>
	);
}

function VideoAssetItem({ item }) {
	const { itemId, assetData } = item;

	return (
		<div pimcore_id={itemId} className={styles.subSectionItem}>
			{/* <div className='video-wrapper'> */}
			<video className={styles.subSectionItem__child} preload='metadata' autoPlay playsInline muted loop loading='lazy'>
				<source src={assetData?.originalAssetPath} type={assetData?.mimeType}></source>
				<source src={assetData?.originalAssetPath} type='video/mp4'></source>
			</video>
			{/* </div> */}
			<HoverDetails id={itemId} />
		</div>
	);
}

function HoverDetails({ id }) {
	const {t}=useTranslation()
	const [isDownloading, setIsDownloading] = useState(false)
	const { setAssetPopUpId } = useProductDetails();
	const { data } = useAsset(id);
	const downloadAnchor = useRef();
	return (
		<div className={styles.onHoverInfo}>
			<a className='anchorForDownload' ref={downloadAnchor}></a>
			<div className={styles.btns}>
				<div className={`btn-group column`} style={{ alignItems: "center" }}>
					<button
						className='btn btn-primary'
						onClick={() => {
							downloadAsset(data, downloadAnchor, setIsDownloading);
						}}
						disabled={isDownloading}
						>
						<div className='btn-icon'>
							<Download />
							{t("Download")}
							
						</div>
						{isDownloading && <LinearProgress />}
					</button>
					<button
						className='btn btn-secondary'
						onClick={() => {
							setAssetPopUpId(id);
						}}>
						<div className='btn-icon'>
							<FileOpen />
							View Details
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}
