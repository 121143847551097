import { useContext, useEffect, useState } from "react";
import "./Callback.css";
import { fetchAuthSession } from "aws-amplify/auth";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { AppContext } from "../../store/AppProvider";
import { LOGIN_USER } from "../../store/reducer/userReducer";
import { ACCESS_TOKEN, BULLETIN_TOKEN, ID_TOKEN, REDIRECT_PATH, USERTYPES, VIEW_BULLETIN_PATH } from "../../utils/constants";
import { getUserRoles } from "../../service/user";
export default function Callback() {
	const { setUserState } = useContext(AppContext);
	const [loginDone, setLoginDone] = useState(false);
	console.log("Callback reloading");
	useEffect(() => {
		if (!loginDone) {
			fetchAuthSession()
				.then(async (data) => {
					console.log("data:::::::", data);
					const payld = data.tokens.idToken.payload;
					sessionStorage.setItem(ACCESS_TOKEN, data.tokens.accessToken.toString());
					sessionStorage.setItem(ID_TOKEN, data.tokens.idToken.toString());
					let sapUser = null;
					if (!payld["custom:accessrole"]?.toUpperCase().includes("INDIRECT") && payld["custom:usertype"].toUpperCase() !== USERTYPES.INTERNAL) {
						try {
							sapUser = await getUserRoles(payld["custom:sapAccountid"], payld["custom:sapcontactid"]);
						} catch (error) {
							console.log("Error in fetching user");
							console.log(error?.message);
							sapUser = {};
						}
						console.log("sapUser:::::::::", sapUser);
					}
					setUserState({
						type: LOGIN_USER,
						payload: {
							loggedIn: true,
							userInfo: {
								displayName: payld["custom:displayname"],
								family_name: payld["family_name"],
								given_name: payld["given_name"],
								email: payld["email"],
							},
							accessrole: payld?.["custom:accessrole"]?.trim()?.split(",") ?? [],
							sapAccountId: payld["custom:sapAccountid"],
							sapContactId: payld["custom:sapcontactid"],
							userType: payld["custom:usertype"],
							status: payld["custom:status"], //TODO remove after check
							sapUserRole: sapUser?.roles || "",
							customerGroup: sapUser?.customer_group || "",
						},
					});
					setLoginDone("true");
				})
				.catch((error) => {
					console.log(error);
					console.log("Something Went Wrong");
				});
		}
	}, [setUserState,loginDone]);

	useEffect(() => {
		if (loginDone) {
			const redirect = localStorage.getItem(REDIRECT_PATH) ?? "/";
			let path = redirect === VIEW_BULLETIN_PATH ? `${redirect}?token=${localStorage.getItem(BULLETIN_TOKEN)}` : redirect;
			localStorage.removeItem(REDIRECT_PATH);
			localStorage.removeItem(BULLETIN_TOKEN);
			window.location.replace(path);
		}
	}, [loginDone]);

	return <FullPageLoading />;
}

export function FullPageLoading() {
	return (
		<Box className='progressBox'>
			<CircularProgress className='progressBox__Loader' />
		</Box>
	);
}
