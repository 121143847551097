import { Skeleton } from "@mui/material";
import styles from "../../pages/compliances/CompliancePage.module.css";
import productStyles from "../../component/productDetail/productDetails.module.css";
import infoStyle from "../../component/productDetail/ProductInfo.module.css";
import keyStyles from "../../component/productDetail/keyFeatures/keyFeatures.module.css";

export function ShimmerSkeleton() {
	return (
		<div className={styles.assetCard}>
			<div>
				<Skeleton variant='rounded' width={"auto"} height={"100%"} />
			</div>
			<div>
				<div style={{ paddingBottom: "5px" }}>
					<Skeleton variant='rounded' width={"auto"} height={50} />
				</div>
				<div style={{ paddingBottom: "5px" }}>
					<Skeleton variant='rounded' width={"auto"} height={20} />
				</div>
				<div>
					<Skeleton variant='rounded' width={"auto"} height={20} />
				</div>
			</div>
			<div className={`btn-group column`}>
				<div style={{ paddingBottom: "10px" }}>
					<Skeleton variant='rounded' width={130} height={40} />
				</div>
				<div>
					<Skeleton variant='rounded' width={130} height={40} />
				</div>
			</div>
		</div>
	);
}

export function ShimmerSkeletonProduct() {
	return (
		<>
			<div className={`container ${infoStyle.product__container}`}>
				<div className={infoStyle.heroBanner}>
					<div className={infoStyle.hero_info}>
						<div className={infoStyle.hero_info_block}>
							<Skeleton variant='text' width={340} height={40} animation={"wave"} />
							<Skeleton variant='text' width={340} height={70} animation={"wave"} />
						</div>
					</div>
					<div className='image-wrapper' style={{ paddingTop: "30px" }}>
						<Skeleton variant='rounded' width={500} height={290} animation={"wave"} />
					</div>
				</div>
				<div className={`${productStyles.section} ${keyStyles.keyFeatures}`}>
					<div className={productStyles.title}>
						<Skeleton variant='rounded' width={200} height={30} animation={"wave"} />
					</div>
					<div className={keyStyles.keyFeatures__content}>
						<Skeleton variant='rounded' height={300} animation={"wave"} />
						<Skeleton variant='rounded' height={300} animation={"wave"} />
						<Skeleton variant='rounded' height={300} animation={"wave"} />
						<Skeleton variant='rounded' height={300} animation={"wave"} />
						<Skeleton variant='rounded' height={300} animation={"wave"} />
					</div>
				</div>
			</div>
		</>
	);
}
