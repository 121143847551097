import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAssetDetails } from "../service/productsService";

export default function useAsset(pimcoreId) {
	const active = pimcoreId ? true : false;
	return useQuery({
		queryKey: ["asset", String(pimcoreId)],
		queryFn: () => getAssetDetails(pimcoreId),
		enabled: active
	});
}
