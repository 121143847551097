import { produce } from "immer";

export const SET_STATIC_MENUS = "SET_Static_MENUS";
export const setStaticSubmenus = (submenus) => ({
	type: SET_STATIC_MENUS,
	payload: submenus,
});

const staticMenuItemReducer = produce((draft, { payload, type }) => {
	switch (type) {
		case SET_STATIC_MENUS: {
			draft=[...payload]
			break;
		}
		default:
			break;
	}

	return draft;
});

export default staticMenuItemReducer