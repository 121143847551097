import { AccountTree, CloudDownload, Download, Email } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import useAsset from "../../hooks/useAsset";
import { downloadAssetFromBE, getAssetDetails } from "../../service/productsService";
import "./assetPopup.css";
import { useScrollLock } from "../../hooks/useScrollLock";
import EmailPopup from "../emailPopUp/EmailPopup";
import useEscapeKey from "../../hooks/useEscapeKey";
import { Link, useNavigate } from "react-router-dom";
import { useClickAway } from "../../hooks/useClickAway";
import { useTranslation } from "react-i18next";

export default function AssetPopUp({ id: pimcoreId, setAssetDetailsId, customStyles }) {
	const { t } = useTranslation();
	const active = pimcoreId ? true : false;
	const { data, isFetching } = useAsset(pimcoreId);
	const [popEmail, setPopEmail] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const linkedProductsLength = data?.linked_products?.length;
	const onlyOneLinkedProduct = data?.linked_products?.length===1;
	const [showLinkedProducts, setShowLinkedProducts] = useState(false);
	const navigate = useNavigate();
	const handleClose = useCallback(() => {
		setShowLinkedProducts(false);
		setAssetDetailsId(null);
	}, [setAssetDetailsId, setShowLinkedProducts]);
	const downloadAnchor = useRef();

	const scrollLock = useScrollLock();
	useEscapeKey(handleClose, active && !popEmail);
	useEffect(() => {
		if (active) {
			scrollLock(true);
		} else {
			scrollLock(false);
		}

		return () => {
			scrollLock(false);
		};
	}, [active]);
	const buttonRef = useClickAway(() => {
		setShowLinkedProducts(false);
	});
	//TODO asset not found

	const orientation = (function (data) {
		if (data?.assetType === "video") {
			return "row";
		} else {
			if (data?.customSettings?.imageHeight < data?.customSettings?.imageWidth) {
				return "row";
			}
			return "column";
		}
	})(data);

	return (
		<>
			<a className='anchorForDownload' ref={downloadAnchor}></a>
			<div
				className={active ? "backdrop backdrop--show" : "backdrop"}
				style={{ zIndex: "var(--zIndexAssetPopUp)", ...(customStyles || {}) }}
				onClick={handleClose}>
				<div
					className={isFetching ? "loading__layout" : `assetDetails  ${orientation}`}
					onClick={(event) => {
						event.stopPropagation();
					}}>
					{isFetching && <CircularProgress size={80} thickness={2} />}
					{!isFetching && (
						<>
							<div
								className='assetDetails--preview'
								onClick={() => {
									// window.open(data?.originalAssetPath, "_blank", "noopener");
								}}>
								{data?.assetType === "video" ? (
									<div className='video-wrapper'>
										<video
											className='contained-video'
											autoPlay
											playsInline
											muted
											controls
											loop
											onError={(e) => console.error("Video Error:", e)}
											onLoadedData={(e) => console.log("Video loaded")}>
											<source src={data?.originalAssetPath} type={data?.mimeType}></source>
											<source src={data?.originalAssetPath} type='video/mp4'></source>
										</video>
									</div>
								) : (
									<>
										<div
											className='image-download'
											onClick={() => {
												downloadAsset(data, downloadAnchor, setDownloading);
											}}>
											<CloudDownload style={{ fontSize: "70px", color: "white" }} />
										</div>
										<div className='image-wrapper'>
											<img src={data?.largeAssetPath} className='contained-image' alt='' loading='eager' />
										</div>
									</>
								)}
							</div>
							<div className={`assetDetails--content ${orientation}`}>
								<div>
									<h3 className='assetDetails--heading'>Assets Details</h3>
									<div className='assetDetails--body'>
										<ExtractAssetDetails assetInfo={data} />
									</div>
								</div>
								<div className='btns'>
									<div className={`btn-group ${orientation === "row" ? "column" : ""}`}>
										<button
											title='Download Asset'
											className='btn btn-secondary'
											onClick={() => {
												downloadAsset(data, downloadAnchor, setDownloading);
											}}
											disabled={downloading}>
											<div className='btn-icon'>
												<Download />
												{t("Download")}
											</div>
											{downloading && <LinearProgress />}
										</button>
										<button
											title='Send Email'
											className='btn btn-secondary'
											onClick={() => {
												setPopEmail(true);
											}}>
											<div className='btn-icon'>
												<Email />
												{t("Email")}
											</div>
										</button>
										{/* {Boolean(linkedProductsLength) && (
											<button
												title={onlyOneLinkedProduct ? "Linked Products" : "Linked Product"}
												ref={buttonRef}
												className='btn btn-primary btn__hasDropdown'
												disabled={!linkedProductsLength || onlyOneLinkedProduct }
												onClick={() => {
													if (onlyOneLinkedProduct) {
														navigate(`/product/${data.linked_products[0].product_uri}`);
														handleClose();
													} else if (linkedProductsLength > 1) {
														setShowLinkedProducts(true);
													}
													if (showLinkedProducts) {
														setShowLinkedProducts(false);
													}
												}}>
												<div className='btn-icon'>
													<AccountTree />
													Product
												</div>
												{showLinkedProducts && <ButtonDialog productsArray={data?.linked_products} handleClose={handleClose} />}
											</button>
										)} */}
									</div>
								</div>
							</div>
							<div className='close-button' onClick={handleClose}>
								<CloseIcon fontSize='large' />
							</div>
						</>
					)}
				</div>
			</div>
			<EmailPopup pimcoreId={pimcoreId} isProduct={false} active={popEmail} setClose={setPopEmail} customStyles={{ position: "fixed" }} />
		</>
	);
}

function ExtractAssetDetails({ assetInfo }) {
	const { t } = useTranslation();
	const divs = [];

	if (assetInfo?.assetMetadata?.name) {
		divs.push({ title: "Name", value: assetInfo?.assetMetadata?.name });
	} else {
		divs.push({ title: "Name", value: assetInfo?.fileName });
	}
	if (assetInfo?.assetMetadata?.description) {
		divs.push({ title: t("Description"), value: assetInfo?.assetMetadata?.description });
	}
	if (assetInfo?.assetMetadata?.assetType) {
		divs.push({ title: "Asset Type", value: assetInfo?.assetMetadata?.assetType });
	} else {
		divs.push({ title: "Asset Type", value: assetInfo?.assetType });
	}
	if (assetInfo?.extension) {
		divs.push({ title: t("Format"), value: assetInfo?.extension.toUpperCase() });
	}
	if (assetInfo?.assetMetadata?.expirationDate) {
		divs.push({ title: t("Expiration Date"), value: assetInfo?.assetMetadata?.expirationDate });
	}
	if (assetInfo?.customSettings?.embeddedMetaData?.FileSize) {
		divs.push({ title: t("File Size"), value: assetInfo?.customSettings?.embeddedMetaData?.FileSize });
	}
	if (assetInfo?.customSettings?.embeddedMetaData?.ImageSize) {
		divs.push({ title: "Asset Size", value: assetInfo?.customSettings?.embeddedMetaData?.ImageSize });
	}

	return divs.map((v) => {
		return (
			<div className='assetDetails--body--item' key={v.title}>
				<div className='title'>
					<span>{v.title} :</span>
				</div>
				<div className='value'>{v.value}</div>
			</div>
		);
	});
}

//TODO check download speed in prod

//Downloading from backend, because PIMCORE CORS
export async function downloadAsset(data, downloadAnchor, setDownloading) {
	try {
		setDownloading(true);
		let url = "";
		if (data?.assetType === "video") {
			url = data?.originalAssetPath;
		} else {
			const base64Response = await downloadAssetFromBE(data?.originalAssetPath,data?.id);
			if (base64Response?.downloadUrl) {
				url = base64Response?.downloadUrl;
			} else {
				const binaryString = atob(base64Response);
				const bytes = new Uint8Array(binaryString.length);
				for (let i = 0; i < binaryString.length; i++) {
					bytes[i] = binaryString.charCodeAt(i);
				}
				const blob = new Blob([bytes]);
				url = window.URL.createObjectURL(blob);
			}
		}
		downloadAnchor.current.href = url;
		downloadAnchor.current.download = data?.fileName || "SONY Asset";
		if (data?.assetType === "video") {
			downloadAnchor.current.target = "_blank";
		} else {
			downloadAnchor.current.target = "_self";
		}
		downloadAnchor.current.click();
	} catch (error) {
		console.log(error);
	} finally {
		setDownloading(false);
	}
}
function ButtonDialog({ productsArray, handleClose }) {
	return (
		<div className='btn--dropdown'>
			{productsArray.map(({ pimcore_id, product_uri, cfm_name }) => {
				return (
					<Link key={pimcore_id} to={`/product/${product_uri}`} onClick={handleClose} className='btn--dropdown--links'>
						{cfm_name}
					</Link>
				);
			})}
		</div>
	);
}
