// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// import XIcon from '@mui/icons-material/X';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import { AppContext } from "../../store/AppProvider";
import "./Home.css";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import whiteLogo from "../../assets/dealer-source-white.png"
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import useUser from "../../hooks/useUser";
export default function Home() {
	const { t } = useTranslation();
	const {activeUser}=useUser()
	useDocumentTitle("Home")
	return (
		<>
			<div className='home'>
				<section className='home__heroImage'  aria-hidden>
					<img className="home__heroImage--logo" src={whiteLogo}/>
				</section>
				{/* <section className='home__followUS' >
                <span >Follow us</span>
                <div id='followUS' className="home__followUS--social-media" >
                    <a role='button' className='fb' target='_blank' href="https://www.facebook.com/SonyProUSA">
                        <FacebookOutlinedIcon sx={{ verticalAlign: 'bottom' }} />
                    </a>
                    <a role='button' className='twitter' target='_blank' href="https://twitter.com/SonyProUSA">
                        <XIcon sx={{ verticalAlign: 'bottom' }} />
                    </a>
                    <a role='button' className='insta' target='_blank' href="https://www.instagram.com/sonyprousa/">
                        <InstagramIcon sx={{ verticalAlign: 'bottom' }} />
                    </a>
                    <a role='button' className='youtube' target='_blank' href="https://www.youtube.com/user/SonyProfessionalUSA">
                        <YouTubeIcon sx={{ verticalAlign: 'bottom' }} />
                    </a>
                </div>
            </section> */}
				<section className='home__information '>
					<h1>
						Hello <span>{`${activeUser.given_name},`}</span>
					</h1>
					<h1>{t("Welcome to Dealer Source")}!</h1>
					<p>Our Dealer Source portal has a brand-new look!</p>
					<p>
						Whether you're looking for the latest announcements, logos, specs, images, rebates and more, we hope you'll find this enhanced site easy to navigate to locate the resources you use the most.
					</p>
					{/* <p>'Contact Us'</p> */}
				
					<p>We value our partnership!</p>
				</section>
				
			</div>
		</>
	);
}
