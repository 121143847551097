import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ message, onConfirm, onCancel,isLoading }) => {
  return (
    <div className="confirmation-modal">
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>{message}</h2>
          <div className="modal-actions">
            <button className="confirm-btn" onClick={onConfirm} disabled={isLoading}>Confirm</button>
            <button className="cancel-btn" onClick={onCancel}disabled={isLoading}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
