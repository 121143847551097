import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./specifications.module.css";
import productStyles from "../productDetails.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function Specifications() {
	const { t } = useTranslation();
	const {
		productDetails: { technicalSpecifications },
		lng,
	} = useProductDetails();

	const specArray = technicalSpecifications[lng] ?? technicalSpecifications["en_US"];

	const groupedSpec = useMemo(() => {
		return specArray.reduce((acc, curr) => {
			// const group_id = curr["Attribute Group Id"]; TODO why is this not same for same group_name SAL-70400G2
			const group_name = curr["Attribute Group Name"].trim();
			if (!acc[group_name]) {
				acc[group_name] = [];
			}
			acc[group_name].push(curr);
			return acc;
		}, {});
	}, [specArray]);

	const groupedSpecArray = useMemo(() => {
		return Object.keys(groupedSpec).map((groupName) => {
			return {
				groupName,
				specs: groupedSpec[groupName],
			};
		}).sort((a,b)=>a.groupName-b.groupName);
	}, [groupedSpec]);

	// const layoutCol = useMasonryLayout(groupedSpecArray, (item) => {
	// 	const { groupName, specs } = item;
	// 	const groDiv = document.createElement("li");
	// 	groDiv.className = styles.list_item;
	// 	groDiv.innerHTML = `<div class="${styles.title}">${groupName}</div>
	// 	            		<div class="${styles.content}">
	// 	                		${specs.map((spec) => `<div>${spec["Attribute Name"]}: ${spec["Value"]}</div>`).join("")}
	// 	           			</div>`;
	// 	return groDiv;
	// });
	const [isOpen, setIsOpen] = useState(() => {
		return Array(groupedSpecArray.length).fill(false);
	});
	return (
		<section className={`${productStyles.section} ${styles.specifications}`}>
			<div className={`${productStyles.title}`}>{t("Specifications")}</div>
			<div className={styles.specifications__container}>
				<div className={styles.controls}>
					<button
						className='btn btn-primary'
						onClick={() => {
							setIsOpen((prev) => prev.map(() => true));
						}}>
						Expand All
						<span className='mui-icon'>
							<ExpandMore fontSize='large' />
						</span>
					</button>
					<button
						className='btn btn-primary'
						onClick={() => {
							setIsOpen((prev) => prev.map(() => false));
						}}>
						Collapse All
						<span className='mui-icon'>
							<ExpandLess fontSize='large' />
						</span>
					</button>
				</div>
				<ul className={styles.specifications__list}>
					{groupedSpecArray &&
						groupedSpecArray.map((item, index) => {
							return <SpecCard item={item} key={item.groupName} index={index} setOpen={setIsOpen} open={isOpen} />;
						})}
				</ul>
			</div>
		</section>
	);
}
Specifications.shouldRender = ({ productDetails: { technicalSpecifications }, lng }) => {
	if (!technicalSpecifications) return false;
	const specArray = technicalSpecifications[lng] ?? technicalSpecifications["en_US"];
	if (!specArray || specArray?.length===0) return false;
	return true;
};
export default Specifications;

function SpecCard({ item, index, setOpen, open }) {
	const { groupName, specs } = item;
	const isOpen = open[index];
	return (
		<li className={styles.list_item} key={groupName}>
			<div
				className={styles.list__title}
				onClick={(event) => {
					event.stopPropagation();
					setOpen((prev) => {
						return prev.map((item, i) => {
							if (i === index) {
								return !item;
							}
							return item;
						});
					});
				}}>
				<span>{groupName}</span>
				{<span className='mui-icon'>{isOpen ? <ExpandLess fontSize='large' /> : <ExpandMore fontSize='large' />}</span>}
			</div>
			<ol className={isOpen ? `${styles.list__content} ${styles.open}` : `${styles.list__content}`}>
				{specs.map((spec) => {
					const key = spec["Attribute Id"];
					const name = spec["Attribute Name"];
					const value = spec["Value"];
					return (
						<li key={key??name}>
							<p>
								{name} : <span className={styles.spec__value}>{value}</span>
							</p>
						</li>
					);
				})}
			</ol>
		</li>
	);
}
