import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";

const privateAxios = AxiosInstancePrivate();

export const getSearchCriteria = async () => {
	const response = await privateAxios.get(urls.getCriteria);
	return response?.data;
};

export const getSearchResults = async (pagination,sortModel,searchData) => {
	const response = await privateAxios.post(urls.searchResult, {pagination,sortModel,...searchData});
	return response?.data;
};

export const getbulletinSignedURL = async ( bulletinNo, jwtToken ) =>{
	const response = await privateAxios.post(urls.bulletinSignedURL, {bulletinNo, jwtToken});
	return response?.data;
}

export const downloadBulletinPDF = async (salesnewsNumbers) => {
    const response = await privateAxios.post(urls.downloadBulletinPDF, {salesnewsNumbers}); 
    return response?.data;
}
export const getSalesNewsMailLink = async (token) => {
    const response = await privateAxios.get(urls.viewBulletin, { params: { token: token } }); 
    return response?.data;
}
export const getSalesNewsMailLinkAuth = async (token) => {
    const response = await privateAxios.get(urls.viewAuth, { params: { token: token } }); 
    return response?.data;
}