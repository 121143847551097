import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";
import { BIZ_LOCATION_FILE_NAME, CONTRACT_HEADER_FILE_NAME, HEADER_AND_BIZLOCATION_TYPE } from "../utils/constants";
import { getSignedUrlForTemplate, uploadTemplateUsingSignedUrl } from "./contractTemplate";

const privateAxios = AxiosInstancePrivate();

export async function getContractCriteria() {
	const res = await privateAxios.get(urls.contractCriteria);
	return res.data;
}
export async function searchContracts(pagination, sortModel, searchData) {
	const res = await privateAxios.post(urls.searchContracts, { pagination, sortModel, ...searchData });
	return res.data;
}

export const deleteContract = async (contract) => {
	try {
		const response = await privateAxios.delete(urls.deleteContract, { params: { contractNumber: contract.contract_number, pdf_doc_name: contract.pdf_doc_name.pdf_doc_name } });
		return response.data;
	} catch (error) {
		console.error('Error deleting contract:', error);
		throw error;
	}
};

export const listContractTemplateTypes = async () => {
	try {
		const response = await privateAxios.get(urls.generateContracts, null);
		console.log("Contract template types:::::::", response);
		return response.data;
	} catch (error) {
		console.log("Error getting template list", error);
	}
}

export const generateContract = async ({ templateType }, headerFile, locationFile) => {
	console.log("Inside generateContract::::::::", templateType, headerFile, locationFile);
	const headeFileDetails = { fileName: `${CONTRACT_HEADER_FILE_NAME}.xlsx`, templateType: HEADER_AND_BIZLOCATION_TYPE };
	const locationFileDetails = { fileName: `${BIZ_LOCATION_FILE_NAME}.xlsx`, templateType: HEADER_AND_BIZLOCATION_TYPE };

	try {
		const [headerFileUrl, locationFileUrl] = await Promise.all(
			[getSignedUrlForTemplate(headeFileDetails),
			getSignedUrlForTemplate(locationFileDetails)]
		);
		console.log(`headeFileUrl:::${headerFileUrl}
			locationFileUrl::::::${locationFileUrl}`);
		const [hederUploadResponse, locationFileUploadResponse] = await Promise.all(
			[uploadTemplateUsingSignedUrl(headerFileUrl, headerFile),
			uploadTemplateUsingSignedUrl(locationFileUrl, locationFile)]
		);
		console.log(`hederUploadResponse:::${hederUploadResponse}
			locationFileUploadResponse::::::${locationFileUploadResponse}`);
		const res = await privateAxios.post(urls.generateContracts, { boiler_plate_id: templateType });
		console.log("res::::::::::::::::::", res);
		return res;
	} catch (error) {
		console.error('Error in generateContract:', error);
	}
};

export const updateLockStatus = async (contract) => {
	console.log("updateLockStatus:::::::", contract);
	try {
		const { contract_or_schedule, key, contract_number, lock_status } = contract;
		const payload =
			contract_or_schedule === "Contract" ?
				{
					contractNumber: contract_number,
					lockStatus: lock_status,
					salesAgreementType: "CONTRACT",
				} :
				{
					contractNumber: contract_number,
					lockStatus: lock_status,
					salesAgreementType: "SCHEDULE",
					scheduleId: key
				};
		const response = await privateAxios.put(urls.toggleLockStatus, payload);
		console.log("updateLockStatus response::::::::", response)
		return response;
	} catch (error) {
		console.error('Error amending schedules:', error);
		throw error;
	}
};
