import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AddScheduleTemplate from './AddScheduleTemplate';
import EditScheduleTemplate from './EditScheduleTemplate';
import { fetchTemplates, deleteTemplate } from '../../../../service/contractTemplate';
import ConfirmationModal from './ConfirmationModal'; 
import { Edit, Delete } from '@mui/icons-material'; 
import './ManageScheduleTemplates.css';
import { PdfLink } from '../../../../component/searchPage/PdfLink';
import { SCHEDULE_TEMPLATE_TYPE } from '../../../../utils/constants';
import { useTranslation } from "react-i18next";
import { PageLoader } from "../../../../component/loader/PLoader";

const ManageScheduleTemplates = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [deleteTemplateId, setDeleteTemplateId] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    try {
      setIsLoading(true);
      const data = await fetchTemplates(SCHEDULE_TEMPLATE_TYPE);
      setTemplates(data?.rows);
      setError(null); 
    } catch (err) {
      setError('Failed to load contract templates. Please try again later.');
    }finally{
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteTemplate(deleteTemplateId,SCHEDULE_TEMPLATE_TYPE);
      loadTemplates(); 
      setIsDeleteModalOpen(false); 
    } catch (err) {
      setError('Failed to delete the template. Please try again later.');
      setIsDeleteModalOpen(false); 
    }finally{
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteTemplateId(id); 
    setIsDeleteModalOpen(true); 
  };
  function renderLink(params) {
    const {pdf_schedule_name,link} = params.value ?? '';
    return (
      <PdfLink href={`${link}`} tabIndex={params.tabIndex}>
      {pdf_schedule_name}
      </PdfLink>
    );
    };

 const columns = [
     { field: 'id', headerName: 'Template ID', flex: 0.5},
     { field: 'pdf_schedule_name', headerName: 'Template Name', flex: 1 ,renderCell:renderLink,valueFormatter:(value) =>  value.pdf_schedule_name},
     { field: 'schedule_user_type', headerName: t("Contract Type"), flex: 0.5 },
     {
       field: 'actions',
       headerName: 'Actions',
       flex: 0.5,
       renderCell: (params) => (
         <div className="actions">
           <button className="edit-btn" onClick={() => setEditTemplate(params.row)}>
             <Edit fontSize="small" />
           </button>
           <button className="delete-btn" onClick={() => handleDeleteClick(params.row.id)}>
             <Delete fontSize="small" />
           </button>
         </div>
       ),
     },
   ];

  return (
    <div className="manage-contract-templates">
       <PageLoader show={isLoading} />
      <h1 className='page-heading'>Manage Schedule Templates</h1>
      <hr className='horizontal-ruler'/>
      {/* {error && <div className="error-message">{error}</div>} */}
      <button className="add-new-file-btn" onClick={() => setIsAddModalOpen(true)}>{t("Add New File")}</button>
      <div className="data-grid-container">
        <DataGrid rows={templates} columns={columns} pageSize={5} />
      </div>
      {isAddModalOpen && <AddScheduleTemplate onClose={() => setIsAddModalOpen(false)} onSuccess={loadTemplates} />}
      {editTemplate && <EditScheduleTemplate template={editTemplate} onClose={() => setEditTemplate(null)} onSuccess={loadTemplates} />}
      
      {isDeleteModalOpen && (
        <ConfirmationModal 
          message="Are you sure you want to delete this template?" 
          onConfirm={handleDelete} 
          onCancel={() => setIsDeleteModalOpen(false)} 
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ManageScheduleTemplates;
