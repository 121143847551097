import React, { useCallback, useState } from "react";
import "./Download.css";
import { ArrowDownward, CheckCircle, Error } from "@mui/icons-material";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSearchResultContext } from "../searchPage/SearchResult";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
function Download() {
	const { t } = useTranslation();
	const [openMenu, setOpenMenu] = useState(true);
	const { downloadPromises, clearDownloads } = useSearchResultContext();
	const [promiseStatus, setPromiseStatus] = useState([]);
	const isEmpty = downloadPromises.length === 0;
	const addPromiseStatus = (status) => {
		setPromiseStatus((prevState) => {
			return [...prevState, status];
		});
	};

	const toggleMenu = () => {
		setOpenMenu((prevState) => {
			return !prevState;
		});
	};

	useEffect(() => {
		if (!isEmpty && downloadPromises.length === promiseStatus.length) {
			setOpenMenu(true);
			setTimeout(clearDownloads, 5000);
			// const overAllStatus=promiseStatus.reduce((acc,curr)=>acc&&curr,true)
			// if(overAllStatus){
			//     setTimeout(clearDownloads,2000)
			// }
		}
	}, [promiseStatus]);

	return (
		<>
			{!isEmpty && (
				<div className='download'>
					<div className='downloadHead'>
						<span>{t("Downloads")}</span>
						<span onClick={toggleMenu}>
							<ArrowDownward />
						</span>
					</div>
					<div className={openMenu ? "downloadBody" : "downloadBody close"}>
						{downloadPromises.map((ele, i) => {
							return <DownloadItem key={ele.title + i} item={ele} addPromiseStatus={addPromiseStatus} />;
						})}
					</div>
				</div>
			)}
		</>
	);
}

function DownloadItem({ item, addPromiseStatus }) {
	const [promiseState, setPromiseState] = useState({ success: false, failure: false });
	const promiseCallback = useRef(false);
    const anchorRef=useRef();

	useEffect(() => {
		if (promiseCallback.current) {
			return;
		}
		promiseCallback.current = true;
		item.fn.then(
			(link) => {
				setPromiseState({ success: true, failure: false });
				addPromiseStatus(true);
                anchorRef.current.href=link;
                anchorRef.current.click()
			},
			() => {
				setPromiseState({ success: false, failure: true });
				addPromiseStatus(false);
			}
		);
	}, []);

	return (
		<>
			<div className='downloadItem'>
				<span><a ref={anchorRef} href="" download={item.title}>{item.title}</a></span>
				<span>
					{promiseState.success ? (
						<CheckCircle color={"success"} />
					) : promiseState.failure ? (
						<Error color={"error"} />
					) : (
						<CircularProgress size='25px' />
					)}
				</span>
			</div>
		</>
	);
}

export default Download;
