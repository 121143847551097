import { CircularProgress } from "@mui/material";
// import "./PLoader.css";

export const PageLoader = ({ show }) => {
	return (
		<>
			<div className={show ? "backdrop backdrop--show" : "backdrop"} >
				<CircularProgress size={80} thickness={5}/>
			</div>
		</>
	);
};
