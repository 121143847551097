
const frontEnd = {
	local: 'http://localhost:3000',
	aws: "https://dealersource.electronics.sony.com"
}
const backEnd = {
	local: "http://localhost:5000",
	aws: "/api"
}
//https://f2kpikq6zj.execute-api.us-east-1.amazonaws.com

exports.authconfig = {
	Auth: {
		Cognito: {
			userPoolClientId: "29kpsmcf1icggm85durgb1t7ek",
			userPoolId: "us-east-1_sMXzopy60",
			loginWith: {
				oauth: {
					domain: "snaapps.auth.us-east-1.amazoncognito.com",
					scopes: ["openid", "profile"],
					redirectSignIn: [`${frontEnd.aws}/callback`],
					redirectSignOut: [`${frontEnd.aws}`],
					responseType: "code",
				},
			},
			// storage: window.sessionStorage
		},
	},
};

exports.API = backEnd.aws;
exports.urls = {
	getCriteria: "/salesNews/getCriteria",
	searchResult: "/salesNews/searchResults",
	bulletinSignedURL: "/salesNews/bulletinSignedURL",
	downloadBulletinPDF: "/salesNews/downloadBulletinPDF",
	companyList: "/internal/companyList",
	userList: "/internal/users",
	impersonate: "/internal/impersonate",
	contractCriteria: "/contracts/getCriteria",
	searchContracts: "/contracts/searchResults",
	globalSearch: "/globalSearch",
	assets: "/products/assets",
	expiryAssets: "/products/expiringAssets",
	downloadAsset: "/products/downloadAssets",
	catalog: "/products/catalog",
	product: "/products/detail",
	generateContracts: "/contracts/generate",
	manageTemplates: "/contracts/manageTemplate",
	getCompanyDetails: "/admin/companyDetails",
	manageContact: "/admin/user",
	getContracts: "/admin/contracts",
	reviewContract: "/admin/reviewContract",
	getUserRoles: "/user/roles",
	logout: "/user/logout",
	deleteContract: "/contracts/deleteContract",
	getSignedUrl: "/contracts/signedUrl",
	amendSchedules: "/contracts/amend",
	viewBulletin: "/salesNews/viewBulletin",
	viewAuth: "/salesNews/viewAuth",
	toggleLockStatus: "/contracts/toggleLock"
};
