import { useQuery, useQueryClient } from "@tanstack/react-query";
import Heading from "../../component/heading/Heading";
import styles from "./expiryAssets.module.css";
import { PageLoader } from "../../component/loader/PLoader";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { expiryAssets } from "../../service/productsService";
import SearchResultPagination from "../../component/searchPage/SearchResultPagination";
import AssetPopUp from "../../component/assetPopUp/AssetPopup";
import { useTranslation } from "react-i18next";

function CustomNoAssetsOverlay() {
	return <p className={styles.noRowsOverlay}>No Assets Expiring in Near Future</p>;
}

const columns = (t) => [
	{ field: "fileName", headerName: t("File name"), minWidth: 300, flex: 1, sortable: false },
	{ field: "derivedAssetType", headerName: "Asset Type", width: 200, sortable: false },
	{ field: "mimeType", headerName: "File Type", width: 200, sortable: false },
	{
		field: "extractedExpiry",
		headerName: "Expiry",
		width: 200,
		sort: "asc",
		type: "date",
		valueGetter: (value) => value && new Date(value),
	},
];

function ExpiryAssets() {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [assetDetailsId, setAssetDetailsId] = useState(null);
	const { isPending, isFetching, data, isError,error } = useQuery({
		queryKey: ["expiryAssets", paginationModel],
		queryFn: () => {
			return expiryAssets(paginationModel);
		},
		placeholderData: (previousData) => previousData,
		retry:0
	});
	const rowCountRef = useRef(data?.total_count || 0);
	const rowCount = useMemo(() => {
		if (data?.total_count !== undefined) {
			rowCountRef.current = data.total_count;
		}
		return rowCountRef.current;
	}, [data?.total_count]);
	const daysToExpiry = useMemo(() => {
		if (data?.daysToExpiry !== undefined) {
			return data.daysToExpiry;
		}
		return "few";
	}, [data?.daysToExpiry]);
	useEffect(() => {
		if (data?.rows) {
			data.rows.map((assetData) => {
				if (!assetData.id) return;
				queryClient.setQueryData(["asset", String(assetData.id)], assetData);
			});
		}
	}, [data]);
	const notDirectReseller=useMemo(()=>{
		if(isError && error.response.data.message==='NOT DIRECT RESELLER'){
			return "UNAUTHORIZED"
		}else if(isError){
			return "SOMETHING WENT WRONG"
		}
		return false
	},[isError])
	// const [sortModel, setSortModel] = useState(() => {
	//         let col = columns.find((v) => v?.sort) ?? columns[0];
	//         return [
	//             {
	//                 field: col.field,
	//                 sort: col.sort ?? "asc",
	//             },
	//         ];
	//     });
	return (
		<div className={`container ${styles.expiry__container}`}>
			<PageLoader show={isPending} />
			<Heading title={"Expiring Assets"} />
			{notDirectReseller && <p className={styles.error}>{notDirectReseller}</p>}
			{!notDirectReseller && (
				<div className={styles.dataGrid}>
					<h2 className={styles.subHeading}>Assets Expiring in {daysToExpiry} days</h2>
					{/* By default, the Data Grid looks for a property named id in the data set to get that identifier. */}
					<DataGrid
						autoHeight
						disableRowSelectionOnClick
						keepNonExistentRowsSelected
						checkboxSelection
						rows={data?.rows}
						columns={columns(t)}
						paginationMode='server'
						loading={isFetching}
						pageSizeOptions={[10, 20, 50]}
						onPaginationModelChange={setPaginationModel}
						paginationModel={paginationModel}
						rowCount={rowCount}
						disableColumnMenu
						sortingMode='server'
						disableColumnSorting
						onRowClick={({ id }) => {
							setAssetDetailsId(id);
						}}
						sx={{
							// disable cell selection style
							".MuiDataGrid-cell:focus": {
								outline: "none",
							},
							// pointer cursor on ALL rows
							"& .MuiDataGrid-row:hover": {
								cursor: "pointer",
								// color: "blue",
							},
						}}
						// onSortModelChange={useCallback((newSortModel) => setSortModel(newSortModel), [])}
						// sortModel={sortModel}
						slots={{ pagination: SearchResultPagination, noRowsOverlay: CustomNoAssetsOverlay }}
					/>
				</div>
			)}
			<AssetPopUp id={assetDetailsId} setAssetDetailsId={setAssetDetailsId} />
		</div>
	);
}
export default ExpiryAssets;
