import { useLayoutEffect, useState } from "react";

export function useScrollLock() {
	const [scrollLock, setScrollLock] = useState(false);
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		if (scrollLock) document.body.style.overflow = "hidden";
		else document.body.style.overflow = originalStyle;
		return () => {
			document.body.style.overflow = originalStyle;
		};
	}, [scrollLock]);
	return setScrollLock;
}
