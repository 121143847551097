import React from "react";
import "./Login.css"; // Create a CSS file for styles
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import CampaignIcon from "@mui/icons-material/Campaign";
import whiteLogo from "../../assets/dealer-source-white.png"
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export default function Login() {

	useDocumentTitle("Login")
	return (
		<div className='login'>
			<div className='banner'>
				<div className='container'>
					<div className='banner_content'>
						{/* <h1>Welcome to</h1> */}
						<img className="banner_content--logo" src={whiteLogo} />
						
						{/* <p>Get access to the resources you need as a Sony Authorized Dealer.</p>
						// <p>From product information to logos, to important updates, this is your ultimate resource.</p> */}
						{/* // <div className='buttons'> */}
							
							
						{/* <button className='register-button'>REGISTER</button> */}
						{/* </div> */}
					</div>
					<div className='dummyDiv'></div>
				</div>
			</div>
			{/* <hr className='hr-separator' /> */}
			
			
			<nav className='features'>
				<ul className='container' aria-hidden>
					<li className='feature' >
						<AutoStoriesIcon className='feature-icon' aria-hidden="true"/>
						<h2>Product Catalog</h2>
						<p>Access features, specifications, images and more.</p>
					</li>
					<li className='feature' >
						<FilterHdrIcon className='feature-icon' aria-hidden="true"/>
						<h2>Logos</h2>
						<p>Download our official logos and guidelines here.</p>
					</li>
					<li className='feature' >
						<CampaignIcon className='feature-icon' aria-hidden="true"/>
						<h2>Important updates​​</h2>
						<p>Find our latest announcements and critical updates.</p>
					</li>
					<li className='feature' >
						<SettingsIcon className='feature-icon' aria-hidden="true"/>
						<h2>Resources​</h2>
						<p>Discover valuable information on products and services.</p>
					</li>
				</ul>
			</nav>
			{/* <hr className='hr-separator' /> */}
			
		</div>
	);
}
