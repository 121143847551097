import { useContext, useEffect, useRef, useState } from "react";
import "./GlobalSearch.css";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { SearchOff } from "@mui/icons-material";
import { Skeleton } from "@mui/material";

import { languageOptions } from "../../utils/Utils";
import { globalSearch } from "../../service/common";
import { useScrollLock } from "../../hooks/useScrollLock";
import { AppContext } from "../../store/AppProvider";
import AssetPopUp from "../assetPopUp/AssetPopup";
import useEscapeKey from "../../hooks/useEscapeKey";

export default function GlobalSearch({ active, setIsActive }) {
	const [searchInput, setSearchInput] = useState("");
	const scrollLock = useScrollLock();
	const [assetDetailsId, setAssetDetailsId] = useState(null);
	const { i18n } = useTranslation();
	const debouncedSearchTerm = useDebounce(searchInput, 500);
	const { isFetching: searchFetching, data: searchResult } = useQuery({
		queryFn: () => globalSearch(debouncedSearchTerm, languageOptions[i18n.resolvedLanguage]["languageCode"]),
		queryKey: [debouncedSearchTerm],
		enabled: debouncedSearchTerm?.trim().length >= 3,
		//todo check this
		// gcTime: ms("1h"),
		// staleTime: ms("1h"),
	});

	const handleSearch = (v) => {
		const searchTerm = v.target.value;
		//TODO add debouch
		setSearchInput(searchTerm);
	};

	const closeGlobalSearch = () => setIsActive(false);
	useEscapeKey(closeGlobalSearch, !assetDetailsId);
	useEffect(() => {
		if (active) {
			scrollLock(true);
		} else {
			scrollLock(false);
			setSearchInput("");
		}

		return () => {
			scrollLock(false);
		};
	}, [active]);

	return (
		<>
			{active && (
				<div className='globalSearch'>
					<div
						className={active ? "backdrop backdrop--show" : "backdrop"}
						onClick={() => {
							setIsActive(false);
						}}>
						<div
							className='globalSearch__modal'
							onClick={(event) => {
								event.stopPropagation();
							}}>
							<header className='globalSearch__modal--searchBar'>
								<form onSubmit={(e) => e.preventDefault()}>
									<label>
										<svg viewBox='0 0 20 20' aria-hidden='true'>
											<path
												d='M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z'
												stroke='currentColor'
												fill='none'
												fillRule='evenodd'
												strokeLinecap='round'
												strokeLinejoin='round'></path>
										</svg>
									</label>
									<input
										value={searchInput}
										onChange={handleSearch}
										placeholder='What are you looking for? Type 3 or more characters to search'
										autoFocus
									/>
									{searchInput && (
										<div
											className='icon'
											onClick={() => {
												setSearchInput("");
											}}>
											<CloseIcon />
										</div>
									)}
								</form>
							</header>
							<div className='globalSearch__modal__main'>
								{searchInput.length >= 3 ? (
									<SearchDetails
										searchResult={searchResult}
										displayAsset={setAssetDetailsId}
										searchFetching={searchFetching}
										closeGlobalSearch={closeGlobalSearch}
									/>
								) : (
									<PreSearch closeGlobalSearch={closeGlobalSearch} />
								)}
							</div>
							<footer></footer>
							<div
								className='closeButton'
								onClick={() => {
									setIsActive(false);
								}}>
								<CloseIcon fontSize='large' />
							</div>
						</div>
					</div>
					<AssetPopUp id={assetDetailsId} setAssetDetailsId={setAssetDetailsId} />
				</div>
			)}
		</>
	);
}
function PreSearch({ closeGlobalSearch }) {
	const { dynamicMenuItems, staticFilteredMenuItems } = useContext(AppContext);
	const menuItems = [...dynamicMenuItems, ...staticFilteredMenuItems];
	const navigate = useNavigate();
	const [openSubMenu, setOpenSubMenu] = useState(null);

	return (
		<div>
			<div className='model__home'>
				{menuItems.map((parent) => {
					const subMenuLen = parent?.subMenus ? Object.keys(parent?.subMenus).length : 0;
					const spanValue = Math.ceil(subMenuLen / 5);
					return (
						<section key={parent.title} style={spanValue > 1 ? { gridRow: `span ${spanValue}` } : {}}>
							<div className='heading model__home--sectionTitle'>{parent.title}</div>
							<ul className='searchHit--list'>
								{parent?.subMenus ? (
									Object.keys(parent?.subMenus)?.map((key) => {
										const subMenu = parent?.subMenus[key];
										return (
											<li
												key={key}
												onClick={() => {
													if (subMenu?.items) {
														setOpenSubMenu(openSubMenu === key ? null : key);
													} else {
														if (subMenu?.route) {
															navigate(`${parent.route}/${subMenu.route}`);
															closeGlobalSearch();
														} else {
															window.open(subMenu.permaLink, "_blank", "noopener");
														}
													}
												}}>
												<div style={{ height: "42px" }}>
													{/* <div className='list--icon'>#</div> */}
													<div className='list--item'>
														<div className='item--title'>
															<span>{key}</span>
															<ul className='searchHit--innerList'>
																{subMenu?.items &&
																	Object.keys(subMenu?.items).map((subKey) => {
																		const subMenuItem = subMenu?.items[subKey];
																		return (
																			<li
																				key={subKey}
																				onClick={() => {
																					if (subMenuItem.permaLink) {
																						window.open(subMenuItem.permaLink, "_blank", "noopener");
																					} else {
																						navigate(`${parent.route}/${subMenu.route}/${subMenuItem.route}`);
																						closeGlobalSearch();
																					}
																				}}>
																				<div style={{ height: "42px" }}>
																					{/* <div className='list--icon'>#</div> */}
																					<div className='list--item'>
																						<div className='item--title'>
																							<span>{subKey}</span>
																						</div>
																					</div>
																				</div>
																			</li>
																		);
																	})}
															</ul>
														</div>
													</div>
												</div>
											</li>
										);
									})
								) : (
									<li
										onClick={() => {
											navigate(`${parent.route}`);
											closeGlobalSearch();
										}}>
										<div style={{ height: "42px" }}>
											{/* <div className='list--icon'>#</div> */}
											<div className='list--item'>
												<div className='item--title'>
													<span>{parent.title}</span>
												</div>
											</div>
										</div>
									</li>
								)}
							</ul>
						</section>
					);
				})}
			</div>
		</div>
	);
}

function SearchDetails({ searchResult, displayAsset, searchFetching, closeGlobalSearch }) {
	const navigate = useNavigate();
	function handleSearchDetailsClick(heading, { pimcore_id, product_uri, categoryUri }) {
		switch (heading) {
			case "categories":
				if (categoryUri.startsWith("ad_&_promo_assets")) {
					if (categoryUri.includes("product_compliance")) {
						navigate(`more/compliance`);
					} else if (categoryUri.includes("map_&_sure")) {
						navigate(`/more/map-&-sure`);
					} else {
						const logoURI = categoryUri.split("/").splice(2).join("/");
						navigate(`/logos/${logoURI}`);
					}
				} else {
					navigate(`/catalog/${categoryUri}`);
				}
				closeGlobalSearch();
				break;
			case "assets":
				displayAsset(pimcore_id);
				break;
			case "products":
				navigate(`/product/${product_uri}`);
				closeGlobalSearch();
				break;
			default:
				break;
		}
	}
	return (
		<div className='searchResult'>
			{searchFetching && (
				<div>
					<Skeleton animation='wave' height={70} />
					<Skeleton animation='wave' height={70} />
					<Skeleton animation='wave' height={70} />
				</div>
			)}
			{searchResult &&
				Object.keys(searchResult).map((heading, i) => {
					const seperator = heading === "categories" ? "<" : "|";
					return (
						<section key={heading} className='searchHit'>
							<div className='heading searchHit--heading'>{heading}</div>
							{heading && (
								<ul key={heading} className='searchHit--list'>
									{searchResult[heading].map((item, index) => {
										const title = item.title?.length > 80 ? item.title.substring(0, 100) + "..." : item.title;
										const helper = item.helper?.length > 80 ? item.helper.substring(0, 100) + "..." : item.helper;
										const sub_title = item.sub_title?.length > 80 ? item.sub_title.substring(0, 100) + "..." : item.sub_title;
										return (
											<li
												key={index} //TODO USE SMTH BETTER
												onClick={() => {
													const pimcore_id = item?.pimcore_id ?? null;
													const product_uri = item?.product_uri ?? null;
													const categoryUri = item?.categoryUri ?? null;
													handleSearchDetailsClick(heading, { pimcore_id, product_uri, categoryUri });
												}}>
												<div>
													<div className='list--icon'>#</div>
													<div className='list--item'>
														<div className='item--title'>
															<span>{title}</span>
															<span>{seperator}</span>
															<span>{helper}</span>
														</div>
														<div className='item--desc'>
															<span>{sub_title}</span>
														</div>
													</div>
												</div>
											</li>
										);
									})}
								</ul>
							)}
						</section>
					);
				})}
			{searchResult && Object.keys(searchResult).length === 0 && (
				<div className='searchResult--empty'>
					<SearchOff sx={{ fontSize: 100 }} />
					<div className='searchResult--empty--text'>No results found</div>
				</div>
			)}
		</div>
	);
}
