import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./dimensions.module.css";
import productStyles from "../productDetails.module.css";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ATTRIBUTES_MAPPING = [
	{
		"PACKAGEHEIGHT": {
			"label": "Package Height",
			"unit": '"',
		},
		"PACKAGELENGTH": {
			"label": "Package Length",
			"unit": '"',
		},
		"PACKAGEVOLUME": {
			"label": "Package Volume",
			"unit": "ft³",
		},
		"PACKAGEWEIGHT": {
			"label": "Package Weight",
			"unit": "lbs",
		},
		"PACKAGEWIDTH": {
			"label": "Package Width",
			"unit": '"',
		},
	},
	{
		"SUBCARTONHEIGHT": {
			"label": "Sub Carton Height",
			"unit": '"',
		},
		"SUBCARTONLENGTH": {
			"label": "Sub Carton Length",
			"unit": '"',
		},
		"SUBCARTONVOLUME": {
			"label": "Sub Carton Volume",
			"unit": "ft³",
		},
		"SUBCARTONWEIGHT": {
			"label": "Sub Carton Weight",
			"unit": "lbs",
		},
		"SUBCARTONWIDTH": {
			"label": "Sub Carton Width",
			"unit": '"',
		},
	},
	{
		"MASTERCARTONHEIGHT": {
			"label": "Master Carton Height",
			"unit": '"',
		},
		"MASTERCARTONLENGTH": {
			"label": "Master Carton Length",
			"unit": '"',
		},
		"MASTERCARTONVOLUME": {
			"label": "Master Carton Volume",
			"unit": "ft³",
		},
		"MASTERCARTONWEIGHT": {
			"label": "Master Carton Weight",
			"unit": "lbs",
		},
		"MASTERCARTONWIDTH": {
			"label": "Master Carton Width",
			"unit": '"',
		},
	},
];

function Dimensions() {
	const { t, i18n } = useTranslation();
	const {
		productDetails: { sapAttributes },
		lng,
	} = useProductDetails();

	const { dimensions } = useMemo(() => getDimensionsData(sapAttributes, lng), [sapAttributes, lng]);

	const dimensionsValue = dimensions.reduce((obj, value) => {
		const attribute__label = value["Attribute Name"];
		const attribute__value = value["Value"];
		if (attribute__label && attribute__value) {
			obj[attribute__label] = attribute__value;
		}
		return obj;
	}, {});
	const languageClass = i18n.resolvedLanguage === "fr-CA" ? {} : styles.section__english;

	return (
		<section className={`${languageClass} ${styles.dimensions}`}>
			<div className={`${productStyles.title} `}>Dimensions</div>
			<div className={styles.dimensions__body}>
				<div className={styles.solo_attribute}>
					<div className={styles.attribute} key={"UPC"}>
						<span className={styles.attribute__label}>UPC</span>
						<span>:</span>
						<span className={styles.attribute__value}>{dimensionsValue["UPC"]}</span>
					</div>
				</div>
				<div className={styles.attribute__cardSection}>
					{ATTRIBUTES_MAPPING.map((group, index) => {
						const hasAttributes = Object.entries(group).some(([attribute]) => dimensionsValue[attribute]);
						return hasAttributes ? (
							<div className={styles.attribute__card} key={index}>
								{Object.entries(group).map(([attribute, obj]) => {
									const value = dimensionsValue[attribute];
									if (!value) return null;
									return (
										<div className={styles.attribute} key={attribute}>
											<div className={styles.attribute__label}>{t(obj["label"])}</div>
											<span>:</span>
											<div className={styles.attribute__value}>{`${value} ${obj["unit"]}`}</div>
										</div>
									);
								})}
							</div>
						) : null;
					})}
				</div>
			</div>
		</section>
	);
}
Dimensions.shouldRender = ({ productDetails: { sapAttributes }, lng }) => {
	return getDimensionsData(sapAttributes, lng).isVisible;
};
function getDimensionsData(sapAttributes, lng) {
	if (!sapAttributes)
		return {
			dimensions: {},
			isVisible: false,
		};

	const dimensions = sapAttributes?.[lng] ?? sapAttributes?.en_US ?? [];

	return {
		dimensions,
		isVisible: Boolean(dimensions.length),
	};
}
export default Dimensions;
