import styles from "./Heading.module.css";

function Heading({title}) {
    return (
       <div className={styles.heading__Body}>
        <h1 className={styles.heading}>{title}</h1>
        <hr className='horizontal-ruler' />
       </div>
    )
}

export default Heading;