import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Navigation.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";

import { AppContext } from "../../store/AppProvider";
import { useNavigate } from "react-router-dom";
import { useScrollLock } from "../../hooks/useScrollLock";
import useToolTip from "../../hooks/useToolTip";
import useViewPortHeight from "../../hooks/useViewPortHeight";

const SideBarContext = createContext();

const Navigation = ({ isOpen, toggleDrawer, gbSearch }) => {
	const [openSubMenus, setOpenSubMenus] = useState();
	const { dynamicMenuItems, staticFilteredMenuItems } = useContext(AppContext);
	const navigate = useNavigate();
	const lockScroll = useScrollLock();
	const viewPortHeight = useViewPortHeight();
	useLayoutEffect(() => {
		const rootStyles = getComputedStyle(document.getElementById("root"));
		const headerHeight = rootStyles.getPropertyValue("--headerHeight").split("px")[0];
		document.getElementById("stickyDiv").style.height = `${viewPortHeight - headerHeight}px`;
	}, [viewPortHeight]);
	
	const handleMenuRoute = (route) => {
		if (route) {
			navigate(route);
			toggleDrawer(false);
		}
	};
	useEffect(() => {
		if (!isOpen) {
			setOpenSubMenus({});
			lockScroll(false);
		}
		if (isOpen) {
			lockScroll(true);
		}
	}, [isOpen]);

	useEffect(() => {
		if (gbSearch) {
			toggleDrawer(false);
		}
	}, [gbSearch]);

	const toggleSubmenu = (itemName) => {
		setOpenSubMenus((prev) => (prev === itemName ? null : itemName));
	};

	return (
		<SideBarContext.Provider value={{ toggleSubmenu, openSubMenus, sideBarOpen: isOpen, toggleSideBar: toggleDrawer }}>
			<nav className={isOpen ? "sideBar active" : "sideBar"} aria-hidden={!isOpen} role='navigation' aria-label='Main navigation'>
				<div id='stickyDiv'>
					<div id='scrollDiv'>
						<div className='sideBar__button'>
							<button
								className='btn-none'
								onClick={() => {
									toggleDrawer(!isOpen);
								}}
								aria-expanded={isOpen}
								aria-label={isOpen ? "Close navigation menu" : "Open navigation menu"}>
								{isOpen ? (
									<CloseIcon sx={{ fontSize: 30, color: "white" }} aria-hidden='true' />
								) : (
									<AppsIcon sx={{ fontSize: 30, color: "white" }} aria-hidden='true' />
								)}
							</button>
						</div>
						<ul className='sideBar__topMenu' role='menubar'>
							{dynamicMenuItems.map((item, index) => (
								<NavigationItems key={item.title} menuItem={item} navigateTo={handleMenuRoute} />
							))}
							{staticFilteredMenuItems.map((item, index) => (
								<NavigationItems key={item.title} menuItem={item} navigateTo={handleMenuRoute} />
							))}
						</ul>
					</div>
				</div>
			</nav>
		</SideBarContext.Provider>
	);
};

function NavigationItems({ menuItem, navigateTo }) {
	const { toggleSubmenu: toggleMenu, openSubMenus: openMenu, sideBarOpen, toggleSideBar } = useContext(SideBarContext);
	const [subMenuState, setSubMenuState] = useState({});
	const isMenuOpen = menuItem.title === openMenu;
	const { toolTipRef, toolTipParent } = useToolTip();
	useEffect(() => {
		setSubMenuState({});
	}, [openMenu]);
	const handleKeyPress = (event) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault();
			handleClick();
		} else if (event.key === "Escape") {
			event.preventDefault();
			toggleSideBar(false);
		}
	};
	const handleClick = () => {
		if (!sideBarOpen) {
			toggleSideBar(true);
			// return;
		}
		if (!menuItem?.subMenus && menuItem?.route) {
			navigateTo(menuItem.route);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		} else if (!menuItem?.subMenus && menuItem?.permaLink) {
			window.open(menuItem.permaLink, "_blank", "noopener noreferrer");
		} else {
			toggleMenu(menuItem.title);
		}
	};
	return (
		<li role='none'>
			<button
				className='sideBar__menuItem btn-none'
				onClick={handleClick}
				onKeyDown={handleKeyPress}
				role='menuitem'
				ref={toolTipParent}
				aria-expanded={menuItem.subMenus ? isMenuOpen : undefined}
				aria-haspopup={menuItem.subMenus ? "true" : undefined}>
				<i className='sideBar__menuItem--icon' aria-hidden='true'>
					{menuItem.icon}
				</i>
				<div className='sideBar__menuItem--title-section'>
					<span className='sideBar__menuItem--title'>{menuItem.title}</span>
					<div className='sideBar__menuItem--helperIcon' aria-hidden='true'>
						{menuItem?.subMenus ? (
							isMenuOpen ? (
								<RemoveIcon />
							) : (
								<AddIcon />
							)
						) : menuItem.permaLink ? (
							<div style={{ marginRight: "5px", lineHeight: "0px" }}>
								<LaunchIcon sx={{ width: 16, height: 16 }} />
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
				<span ref={toolTipRef} className='sideBar__menuItem--tooltip'>
					{menuItem.title}
				</span>
			</button>
			{menuItem.subMenus && (
				<ul className={isMenuOpen ? "sideBar__innerMenu active" : "sideBar__innerMenu"}>
					{Object.keys(menuItem.subMenus).map((subItemName, subIndex) => {
						const subItemObj = menuItem.subMenus[subItemName];
						return (
							<InnerMenuItem
								key={subItemName}
								itemName={subItemName}
								menuItem={menuItem}
								parentRoute={menuItem.route}
								navigateTo={navigateTo}
								setSubMenuState={setSubMenuState}
								openSubMenus={subMenuState}>
								{subMenuState[subItemName] && (
									<ul className='sideBar__innerMostMenu'>
										{subItemObj?.items &&
											Object.keys(subItemObj?.items).map((innerItemName, innerIndex) => {
												const innerItemObj = subItemObj.items[innerItemName];
												return (
													<li
														key={innerItemName}
														onClick={() => {
															if (innerItemObj?.route) {
																navigateTo(`/${menuItem.route}/${subItemObj?.route}/${innerItemObj?.route}`);
																window.scrollTo({
																	top: 0,
																	behavior: "smooth",
																});
															} else window.open(innerItemObj.permaLink, "_blank", "noopener");
														}}>
														<div className='sideBar__menuItem'>
															<span>{innerItemName}</span>
															{innerItemObj?.permaLink && (
																<div style={{ marginRight: "23px" }}>
																	<LaunchIcon sx={{ width: 16, height: 16 }} />
																</div>
															)}
														</div>
													</li>
												);
											})}
									</ul>
								)}
							</InnerMenuItem>
						);
					})}
				</ul>
			)}
		</li>
	);
}

function InnerMenuItem({ children, itemName, menuItem, parentRoute, navigateTo, setSubMenuState, openSubMenus }) {
	const itemObj = menuItem.subMenus[itemName];
	//TODO PRINT sub menu based on permission and current use role
	return (
		<li
			className={openSubMenus[itemName] ? "selected" : undefined}
			onClick={() => {
				if (!itemObj?.items && itemObj?.route) {
					navigateTo(`/${parentRoute}/${itemObj?.route}`);
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				} else if (itemObj?.items) {
					setSubMenuState((prev) => ({ [itemName]: !prev[itemName] }));
				} else {
					window.open(itemObj?.permaLink, "_blank", "noopener");
				}
			}}>
			<div className='sideBar__menuItem'>
				<span>{itemName}</span>
				{itemObj?.items && (
					<div className={openSubMenus[itemName] ? "fa-arrow-down open" : "fa-arrow-down"}>
						<ArrowRightIcon />
					</div>
				)}
				{itemObj?.permaLink && (
					<div style={{ marginRight: "5px" }}>
						<LaunchIcon sx={{ width: 16, height: 16 }} />
					</div>
				)}
			</div>
			{children}
		</li>
	);
}

export default Navigation;
