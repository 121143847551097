
import { useMemo } from "react";
import DOMPurify from "dompurify";
import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./footnotes.module.css";
import productStyles from "../productDetails.module.css";
import { useTranslation } from "react-i18next";
function Footnotes() {
    const { t } = useTranslation();
    const {
        productDetails: { footnotes },
        lng,
    } = useProductDetails();

    const { footnotesString } = useMemo(
        () => getFootnotesData(footnotes, lng),
        [footnotes, lng]
    );

    if (!footnotesString) {
        return null;
    }

    return (
        <section className={`${productStyles.section} ${styles.footnotes}`}>
            <div className={`${productStyles.title}`}>{t("Footnotes")}</div>
            <div className={productStyles.sub_section}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(footnotesString),
                    }}
                ></p>
            </div>
        </section>
    );
}

Footnotes.shouldRender = (contextData) => {
    const { 
        productDetails: { footnotes }, 
        lng 
    } = contextData;
    
    return getFootnotesData(footnotes, lng).isVisible;
};
export function getFootnotesData(footnotes, lng){
    if (!footnotes) return {
        footnotesString: "",
        isVisible: false
    };

    const footnotesString = footnotes?.[lng] ?? footnotes?.en_US ?? "";

    return {
        footnotesString,
        isVisible: Boolean(footnotesString)
    };
};
export default Footnotes;
