import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './AddScheduleTemplate.css';
import { uploadTemplate } from '../../../../service/contractTemplate';
import { SCHEDULE_TEMPLATE_TYPE } from '../../../../utils/constants';
import { useTranslation } from "react-i18next";

const AddScheduleTemplate = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    console.log("Uplading Schedule Templates:::",data);
    try {
      await uploadTemplate(data,SCHEDULE_TEMPLATE_TYPE);
      onSuccess();
      onClose();
    } catch (err) {
      setError('Failed to upload the Schedule template. Please try again later.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New Schedule Template</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label htmlFor="scheduleNumber">Schedule #</label>
            <input
              id="scheduleNumber"
              type="text"
              {...register('scheduleNumber')}
              placeholder="unique shedule number"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="usertype">User Type</label>
            <select id="usertype" {...register('usertype')} required>
              <option value="">{t("Select User type")}</option>
              <option value="B">Professional</option>
              <option value="C">Consumer</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="prorateAmount">Prorate Amount</label>
            <input
              id="prorateAmount"
              type="text"
              {...register('prorateAmount')}
              placeholder="prorate amount"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="title">Title</label>
            <input
              id="title"
              type="text"
              {...register('title')}
              placeholder="filename with extension"
              required
            />
          </div>
          
          <div className="input-group">
            <label htmlFor="file">File</label>
            <input
              id="file"
              type="file"
              {...register('file')}
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="modal-actions">
            <button type="submit" className="submit-btn">Submit</button>
            <button type="button" className="close-btn" onClick={onClose}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddScheduleTemplate;
