import { useCallback, useEffect, useRef, useState } from "react";
import { useClickAway } from "./useClickAway";
function useKeyboardDialog() {
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef(null);
    const handleToggle = useCallback((state) => {
        setIsOpen(prev => typeof state === 'boolean' ? state : !prev);
    }, []);

	// const ref=useClickAway(()=>{setIsOpen(false)})

	// useEffect(() => {
	// 	ref.current = containerRef.current;
	// }, [containerRef.current]);

	const handleKeyDown = useCallback(
		(event) => {
            const parentElement=containerRef.current;
			if (!parentElement) return;

			// Get all li elements within the container
			const menuItems = parentElement.querySelectorAll("li");
            if(!menuItems.length)
                return;
			const currentIndex = Array.from(menuItems).findIndex((item) => item === document.activeElement);

			switch (event.key) {
				case "Escape":
					setIsOpen(false);
                    parentElement?.focus(); 
					break;
				case "ArrowDown":
					event.preventDefault();
					if (currentIndex < menuItems.length - 1) {
						menuItems[currentIndex + 1].focus();
					} else {
						// Loop to first item
						menuItems[0].focus();
					}
					break;
				case "ArrowUp":
					event.preventDefault();
					if (currentIndex > 0) {
						menuItems[currentIndex - 1].focus();
					} else {
						// Loop to last item
						menuItems[menuItems.length - 1].focus();
					}
					break;
				case "Tab":
					if (!event.shiftKey && currentIndex === menuItems.length - 1) {
						setIsOpen(false);
					}
					break;
			}
		},
		[]
	);
	const handleFocusIn = useCallback(
		(event) => {
            const parentElement=containerRef.current;
			const targetElement = event.target;

			if (!parentElement) return;

			const isWithinContainer = parentElement.contains(targetElement);
			const isContainerItself = targetElement === parentElement;

			if (isWithinContainer && !isContainerItself) {
				setIsOpen((prev) => (!prev ? true : prev));
			} else if (!isWithinContainer || isContainerItself) {
				setIsOpen(false);
			}
		},
		[]
	);
	useEffect(() => {
        const parentElement=containerRef.current;
		if (!parentElement) return;

		parentElement.addEventListener("keydown", handleKeyDown);
		document.addEventListener("focusin", handleFocusIn);

		return () => {
			parentElement.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("focusin", handleFocusIn);
		};
	}, [ handleKeyDown, handleFocusIn]);

	return [containerRef,isOpen, handleToggle];
}
export default useKeyboardDialog;
