import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";

const privateAxios = AxiosInstancePrivate();

export async function getCompanyList() {
	const res = await privateAxios.get(urls.companyList);
	return res.data;
}
export async function impersonateUser(accountId, contactId) {
	const res = await privateAxios.post(urls.impersonate, { accountId, contactId });
	return res.data;
}
export async function globalSearch(search,locale) {
	const res = await privateAxios.post(urls.globalSearch,{}, {
		params:{
			search,
			locale
		}
	 });
	return res.data;
}
export async function logoutCookies() {
	const res = await privateAxios.get(urls.logout);
	return res.data;
}