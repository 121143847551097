import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import "./AccountSearch.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCompanyList, impersonateUser } from "../../service/common";
import { getUsersList } from "../../service/user";
import ms from "ms";
import { AppContext } from "../../store/AppProvider";
import { DE_IMPERSONATE_USER, IMPERSONATE_USER, loggedInStateInit } from "../../store/reducer/userReducer";
import { useNavigate } from "react-router-dom";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { jwtDecode } from "jwt-decode";
export default function AccountSearch() {
	//TODO check for roles
	const [impersonationState, setImpersonationState] = useState({
		impersonate: false,
		impersonated: false,
		selectedCompany: null,
		selectedUser: null,
	});
	useEffect(() => {
		const { impersonated, impersonatedAccountObj, impersonateContactObj } = loggedInStateInit();
		setImpersonationState((oldState) => ({
			...oldState,
			impersonated,
			selectedCompany: impersonatedAccountObj,
			selectedUser: impersonateContactObj,
		}));
	}, []);
	const client = useQueryClient();
	useDocumentTitle("Account Search");
	const { setUserState } = useContext(AppContext);
	const navigate = useNavigate();
	const {
		isFetching: companylistFetching,
		// isError,
		data: companyList,
	} = useQuery({
		queryKey: ["companyList"],
		queryFn: getCompanyList,
		placeholderData: [],
	});
	const {
		isFetching: userListFetching,
		isError: userListError,
		data: userList,
	} = useQuery({
		queryKey: ["user", impersonationState.selectedCompany],
		queryFn: () => getUsersList(impersonationState.selectedCompany.soldto_number),
		placeholderData: [],
		enabled: Boolean(impersonationState.selectedCompany),
	});
	const {
		isPending: jwtPending,
		isFetching: jwtFetching,
		isSuccess: jwtSuccess,
		isError: jwtError,
		data: jwtData,
	} = useQuery({
		enabled: impersonationState.impersonate,
		queryKey: ["jwt", impersonationState.selectedCompany, impersonationState.selectedUser],
		staleTime: ms("1d"),
		queryFn: () => impersonateUser(impersonationState.selectedCompany.soldto_number, impersonationState.selectedUser.sap_contact_id),
	});

	const handleDeImpersonation = useCallback(() => {
		setUserState({ type: DE_IMPERSONATE_USER });
		client.clear();
		setImpersonationState({ impersonate: false, impersonated: false, selectedCompany: null, selectedUser: null });
	}, [setUserState]);

	useEffect(() => {
		if (jwtData && impersonationState.impersonate) {
			const { given_name, roles, family_name, email, customer_group } = jwtDecode(jwtData);
			setUserState({
				type: IMPERSONATE_USER,
				payload: {
					jwt: jwtData,
					roles:[roles],
					customer_group,
					sapUserInfo: { family_name, email, given_name },
					selectedCompanyObj: impersonationState.selectedCompany,
					selectedUserObj: impersonationState.selectedUser,
				},
			});
			setImpersonationState((oldState) => ({ ...oldState, impersonated: true }));
			navigate("/");
		}
	}, [jwtSuccess, setUserState, impersonationState]);
	return (
		<>
			<div className='accountSearch'>
				<div className='card'>
					<h1>Account Search</h1>

					<Autocomplete
						disablePortal
						id='combo-box-account-id'
						sx={{ width: 300 }}
						options={companyList ?? []}
						loading={companylistFetching}
						getOptionLabel={(option) => option.soldto_name + " " + option.soldto_number}
						getOptionKey={(option) => option.soldto_number}
						isOptionEqualToValue={(option, value) => option.soldto_number === value.soldto_number}
						onChange={(event, value) => {
							setImpersonationState((state) => ({ ...state, selectedCompany: value }));
						}}
						value={impersonationState.selectedCompany}
						disabled={impersonationState.impersonated}
						renderInput={(params) => <TextField {...params} label='Account ID' data-testid='accountList' />}
					/>
					<Autocomplete
						key={impersonationState.selectedCompany}
						disablePortal
						aria-label='User ID Selector'
						id='combo-box-user-id'
						sx={{ width: 300 }}
						options={userList ?? []}
						loading={userListFetching}
						isOptionEqualToValue={(option, value) => option.sap_contact_id === value.sap_contact_id}
						onChange={(event, value) => {
							setImpersonationState((state) => ({ ...state, selectedUser: value }));
						}}
						value={impersonationState.selectedUser}
						disabled={impersonationState.impersonated}
						getOptionLabel={(option) => `${option.first_name} ${option.last_name} - ${option.sap_function_descr}`}
						getOptionKey={(option) => option.sap_contact_id}
						tabIndex={2}
						renderInput={(params) => <TextField {...params} label='User ID' />}
					/>
					{impersonationState.impersonated ? (
						<button
							className='impersonate-btn'
							type='button'
							onClick={() => {
								handleDeImpersonation();
							}}>
							<div>Clear Impersonation</div>
						</button>
					) : (
						<button
							className='impersonate-btn'
							type='button'
							onClick={() => {
								setImpersonationState((oldState) => ({ ...oldState, impersonate: true }));
							}}
							disabled={jwtFetching || !(Boolean(impersonationState.selectedCompany) && Boolean(impersonationState.selectedUser))}>
							<div>{jwtFetching ? "Impersonating" : "Impersonate"}</div>
							{jwtFetching && <LinearProgress />}
						</button>
					)}
				</div>
			</div>
		</>
	);
}
