import { urls } from "../config";
import { AxiosInstancePrivate } from "../utils/AxiosInstance";
import { ACCEPT, REJECT } from "../utils/constants";

const privateAxios = AxiosInstancePrivate();
export const getCompanyDetails = async () => {
    const { data: companyDetails } = await privateAxios.get(urls.getCompanyDetails);
    const { company, companyAddress, usersList } = companyDetails;
    const companyInfo = { ...company[0], ...companyAddress[0] };
    return { companyInfo, usersList };
}

export const deleteContact = async (soldto_number, contact) => {
    const res = await privateAxios.delete(urls.manageContact, { params: { soldToNumber: soldto_number, contact: contact } });
    return res;
}
export const createContact = async (soldto_number, contact) => {
    const res = await privateAxios.post(urls.manageContact, null, { params: { soldToNumber: soldto_number, contact: contact } });
    return res;
}
export const updateContact = async (soldto_number, contact) => {
    const res = await privateAxios.put(urls.manageContact, null, { params: { soldToNumber: soldto_number, contact: contact } });
    return res;
}

export const acceptContract = async (contract, formData) => {
    console.log("Accepting the Contract::::", contract, formData);
    const { contract_number, detail_type, schedule_id, pdf_doc_name: { pdf_doc_name } } = contract;
    console.log("pdf_doc_name::::", pdf_doc_name)
    const { name, title } = formData;
    try {
        let payload = detail_type === "Contract" ? {
            // soldToNumber: "0001001838",//TODO remove from here
            contractNumber: contract_number,
            action: ACCEPT,
            acceptorName: name,
            acceptorTitle: title,
            pdf_doc_name: pdf_doc_name

        } : {
            // soldToNumber: "0001001838",//TODO remove from here
            contractNumber: contract_number,
            scheduleId: schedule_id,
            action: ACCEPT,
            acceptorName: name,
            acceptorTitle: title,
            pdf_doc_name: pdf_doc_name
        }
        const response = await privateAxios.put(urls.reviewContract, payload);
        console.log("fetchContracts::::::", payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching contracts:', error);
        throw error;
    }
};

export const rejectContract = async (contract, formData) => {
    console.log("Rejecting the Contract::::", contract, formData);
    const { contract_number, detail_type, schedule_id, pdf_doc_name: { pdf_doc_name } } = contract;
    const { reason } = formData;
    try {
        let payload = detail_type === "Contract" ? {
            soldToNumber: "0001001838",//TODO remove from here
            contractNumber: contract_number,
            action: REJECT,
            rejectionReason: reason,
            pdf_doc_name: pdf_doc_name
        } : {
            soldToNumber: "0001001838",//TODO remove from here
            contractNumber: contract_number,
            scheduleId: schedule_id,
            action: REJECT,
            rejectionReason: reason,
            pdf_doc_name: pdf_doc_name
        }
        const response = await privateAxios.put(urls.reviewContract, payload);
        console.log("fetchContracts::::::", payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching contracts:', error);
        throw error;
    }
};

export const fetchContracts = async (soldto_number) => {
    try {
        const response = await privateAxios.get(urls.getContracts, { params: { soldToNumber: soldto_number } });
        console.log("fetchContracts::::::", response);
        return await response.data;
    } catch (error) {
        console.error('Error fetching contracts:', error);
        throw error;
    }
};
