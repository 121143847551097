import { createContext, useReducer} from "react";
import { loggedInStateInit, userReducer } from "./reducer/userReducer";
import dynamicMenuItemReducer from "./reducer/dynamicMenuItemReducer";
import { loaderInitialState, loaderReducer } from "./reducer/loaderReducer";
import staticMenuItemReducer from "./reducer/staticMenuItemReducer";
import { errorReducer } from "./reducer/errorsReducer";

export const AppContext = createContext({});

export default function AppProvider({ children }) {
	const [userState, setUserState] = useReducer(userReducer, null, loggedInStateInit);
	const [dynamicMenuItems, setDynamicMenuItems] = useReducer(dynamicMenuItemReducer, []);
	const [staticFilteredMenuItems, setStaticFilteredMenuItems] = useReducer(staticMenuItemReducer, []);
	const [showPLoader, setShowPLoader] = useReducer(loaderReducer, loaderInitialState);
	const [errors,setErrors]=useReducer(errorReducer,{})
	const store = {
		userState,
		setUserState,
		dynamicMenuItems, setDynamicMenuItems,
		staticFilteredMenuItems, setStaticFilteredMenuItems,
		showPLoader, setShowPLoader,
		errors,setErrors
	};
	return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
}
