import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(
		resourcesToBackend((language, namespace) => {
			return import(`./locales/${language}/${namespace}.json`);
		})
	)
	.init({
		fallbackLng: "en-US",
		defaultLocale: "en-US",
		debug: true, // to hide i18 warnings
		interpolation: { escapeValue: false },
		locales: ["en-US", "en-CA", "fr-CA"],
	});

export default i18n;
