import { memo } from "react";

export const PdfLink =memo(function DemoLink(props) {
	const handleClick = (event) => {
	  event.stopPropagation();
	};
  
	return (
	  <a tabIndex={props.tabIndex} onClick={handleClick} href={props.href} target='_blank' rel='noreferrer'>
		{props.children}
	  </a>
	);
  });