import styles from "./relatedProducts.module.css";
import productStyles from "../productDetails.module.css";
import { useProductDetails } from "../productContext/ProductProvider";
import { camilCaseToSentence } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RelatedProducts() {
	const { t } = useTranslation();
	const {
		productDetails: { relatedProductsDetails },
		lng,
	} = useProductDetails();
	const relatedProducts = isObjEmpty(relatedProductsDetails);
	return (
		<section className={`${productStyles.section} ${styles.relatedProducts}`}>
			<div className={`${productStyles.title}`}>{t("Related Products")}</div>
			<div className={styles.relatedProducts__container}>
				{!relatedProducts && Object.keys(relatedProductsDetails).map((key) => {
					const title=camilCaseToSentence(key)
					const items=relatedProductsDetails[key]
					return (
						<RelatedProductSection key={key} titleC={title} items={items} />
					);
				})
				}</div>
		</section>
	);
}

RelatedProducts.shouldRender = ({ productDetails: { relatedProductsDetails } }) => {
	return !isObjEmpty(relatedProductsDetails);
};
function isObjEmpty(obj) {
	return obj && obj.constructor === Object && Object.keys(obj).length === 0;
}
function RelatedProductSection({titleC,items}) {
	
	return <section className={styles.relatedTypeSubSection}>
	<h3 className={styles.subSectionTitle}>{titleC}</h3>
	<div className={styles.subSectionContent}>
		{items.map((item) => {
			return <RelatedProductItem key={item.pimcore_id} item={item}/>
		})}
	</div>
</section>
}
function RelatedProductItem({item}) {
	const { pimcore_id, cfm_name,global_model_name,product_uri,image_url } = item;
	return (
		<Link  pimcore_id={pimcore_id} className={styles.subSectionItem} to={`/product/${product_uri}`}>
			<img className={styles.subSectionItem__child} src={image_url} loading='lazy' />
			<p>{cfm_name ||global_model_name}</p>
		</Link>
	);
}
