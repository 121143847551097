export const loaderInitialState = {
    showLoader: false
  };
  
  export const SHOW_LOADER = "SHOW_LOADER";
  export const HIDE_LOADER = "HIDE_LOADER";
  
  export const loaderReducer = (state, { action }) => {
    switch (action) {
      case HIDE_LOADER:
        return { ...state, showLoader: false };
      case SHOW_LOADER:
        return {
          ...state,
          showLoader: true,
        };
      default:
        return state;
    }
  };
  